import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Upload, message, Modal, Spin } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { get } from "../../../Api/http/httpClient"; // Importar el método get de httpclient

const { Dragger } = Upload;

interface MultimediaProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const Multimedia: React.FC<MultimediaProps> = ({
  user_data,
  setTitleSection,
}) => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [newPhotos, setNewPhotos] = useState<string[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    setTitleSection("Multimedia");
  }, [setTitleSection]);

  // Fetch photos data
  const getPhotos = async () => {
    setLoading(true);
    try {
      const res = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );

      if (res.answer && res.item && res.item.photos) {
        setPhotos(res.item.photos);
      } else {
        message.error("No se encontraron imágenes.");
      }
    } catch (error) {
      message.error("Error al obtener las imágenes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPhotos();
  }, []);

  // Handle the removal of an image
  const handleRemove = (photo: string) => {
    setPhotos(photos.filter((p) => p !== photo));
    message.success("Imagen eliminada correctamente");
  };

  // Handle image upload in base64 format for multiple images
  const handleUpload = (info: any) => {
    const files = info.fileList;
    const newBase64Photos: string[] = [];
    const newPreviewUrls: string[] = [];

    files.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        const base64 = (reader.result as string).split(",")[1]; // Remove the base64 tag
        newBase64Photos.push(base64);
        newPreviewUrls.push(reader.result as string);

        if (newBase64Photos.length === files.length) {
          setNewPhotos(newBase64Photos);
          setPreviewUrls(newPreviewUrls);
          message.success("Imágenes cargadas correctamente.");
        }
      };
      reader.onerror = () => {
        message.error("Error al cargar la imagen.");
      };
    });

    setFileList(files);
  };

  const handleAddNewPhotos = () => {
    if (newPhotos.length > 0) {
      setPhotos([...photos, ...newPhotos]);
      message.success("Imágenes agregadas correctamente");
      handleModalClose();
    } else {
      message.error("Debe subir al menos una imagen válida.");
    }
  };

  const handleModalOpen = () => {
    setModalVisible(true);
    setNewPhotos([]);
    setPreviewUrls([]);
    setFileList([]);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setNewPhotos([]);
    setPreviewUrls([]);
    setFileList([]);
  };

  return (
    <Card bordered={false} className="header-solid mb-24">
      <Row gutter={[24, 24]}>
        {photos.map((photo, index) => (
          <Col span={24} md={12} xl={6} key={index}>
            <Card
              bordered={false}
              className="card-project"
              cover={
                <img
                  alt={`photo-${index}`}
                  src={
                    photo.startsWith("http")
                      ? photo
                      : `data:image/png;base64,${photo}`
                  }
                />
              }
              actions={[
                <Button
                  key="delete"
                  danger
                  type="text"
                  onClick={() => handleRemove(photo)}
                  icon={<DeleteOutlined />}
                >
                  Eliminar
                </Button>,
              ]}
            />
            <div className="card-tag">{""}</div>
          </Col>
        ))}
        <Col xs={24} sm={12} md={8} lg={6} style={{ marginBottom: "20px" }}>
          <Button
            type="dashed"
            onClick={handleModalOpen}
            style={{ width: "100%", height: "150px" }}
          >
            <PlusOutlined /> Agregar Imágenes
          </Button>
        </Col>

        {/* Modal for adding images */}
        <Modal
          title="Agregar Nuevas Imágenes"
          open={modalVisible}
          onCancel={handleModalClose}
          onOk={handleAddNewPhotos}
        >
          <Dragger
            listType="picture"
            multiple
            beforeUpload={() => false}
            accept="image/*"
            onChange={handleUpload}
            fileList={fileList}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">
              Haz clic o arrastra una imagen para subirla
            </p>
          </Dragger>
        </Modal>
      </Row>
    </Card>
  );
};

export default Multimedia;
