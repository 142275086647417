import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Spin,
  message,
  Typography,
  Modal,
} from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { get, put } from "../../../Api/http/httpClient";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import rawData from "../../../utils/data/data.json";

const { Text } = Typography;

/** Interfaces para la data de ubicación */
interface RawCity {
  id: number;
  name: string;
  latitude?: string | null;
  longitude?: string | null;
}

interface RawState {
  id: number;
  name: string;
  state_code?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  cities: RawCity[];
}

interface RawCountry {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  phone_code: string;
  states: RawState[];
}

interface City {
  id: number;
  name: string;
  latitude?: string;
  longitude?: string;
}

interface State {
  id: number;
  name: string;
  state_code?: string;
  latitude?: string;
  longitude?: string;
  cities: City[];
}

interface Country {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  phone_code: string;
  states: State[];
}

interface Option {
  value: string;
  label: string;
}

/** Normaliza la data del JSON */
const normalizeData = (data: RawCountry[]): Country[] => {
  return data.map((country) => ({
    ...country,
    states: country.states.map((state) => ({
      ...state,
      state_code: state.state_code ?? undefined,
      latitude: state.latitude ?? undefined,
      longitude: state.longitude ?? undefined,
      cities: state.cities.map((city) => ({
        ...city,
        latitude: city.latitude ?? undefined,
        longitude: city.longitude ?? undefined,
      })),
    })),
  }));
};

const countriesData: Country[] = normalizeData(rawData as RawCountry[]);

/** Props del componente. Se usa "any" en user_data, pero se puede tipar según el modelo real */
interface GeneralDataProps {
  user_data: any;
}

const GeneralData: React.FC<{ user_data: any; setTitleSection: (title: string) => void }> = ({
  user_data,
  setTitleSection,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [updating, setUpdating] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isNameDisabled, setIsNameDisabled] = useState<boolean>(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);

  // Estados para los selects de ubicación
  const [countries, setCountries] = useState<Option[]>([]);
  const [states, setStates] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const [statesDisabled, setStatesDisabled] = useState<boolean>(true);
  const [citiesDisabled, setCitiesDisabled] = useState<boolean>(true);

  const screens = useBreakpoint();

  // Determinar si el establecimiento es de tipo e-commerce
  const isEcommerce = user_data["custom:establishment"] === "e-commerce";

  // Determinar si el establecimiento es de tipo medical-office o legal-office
  const isMedicalOrLegal =
    user_data["custom:establishment"] === "medical-office" ||
    user_data["custom:establishment"] === "legal-office";

  // Sólo mostrar campos de “restaurante” si NO es eCommerce y NO es medical/legal
  const showRestaurantFields = !isEcommerce && !isMedicalOrLegal;

  useEffect(() => {
    setTitleSection("Datos Generales");
  }, [setTitleSection]);

  const atmosphereOptions = [
    { label: "Formal", value: "formal" },
    { label: "Casual", value: "casual" },
    { label: "Familiar", value: "family" },
    { label: "Romántico", value: "romantic" },
  ];

  const cuisineOptions = [
    { label: "Italiana", value: "italian" },
    { label: "Mexicana", value: "mexican" },
    { label: "Fusión", value: "fusion" },
    { label: "Otra", value: "other" },
  ];

  /** Función para cargar datos del establecimiento */
  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;

        // Ajustar el campo de cocina
        const knownCuisines = ["italian", "mexican", "fusion"];
        const originalCuisine: string = fetchedData.cuisine;
        if (!knownCuisines.includes(originalCuisine)) {
          fetchedData.cuisine = "other";
          fetchedData.cuisine_custom = originalCuisine;
        } else {
          fetchedData.cuisine_custom = "";
        }

        // Procesar el teléfono
        if (fetchedData.phone && fetchedData.countryCode) {
          const cleanedPhone = fetchedData.phone.replace(fetchedData.countryCode, "");
          fetchedData.phone = cleanedPhone;
        }

        // Si el fetchedData incluye "state", lo renombramos a "state_country"
        if (fetchedData.state) {
          fetchedData.state_country = fetchedData.state;
          delete fetchedData.state;
        }

        // Se asume que fetchedData puede incluir address, country, state_country y city
        form.setFieldsValue(fetchedData);
        setOriginalValues(fetchedData);

        // Actualizamos los selects de ubicación:
        if (fetchedData.country) {
          handleCountryChange(fetchedData.country);
          // Retrasamos ligeramente para que se actualicen las opciones y luego asignamos el valor del departamento y la ciudad
          setTimeout(() => {
            if (fetchedData.state_country) {
              // Ahora se pasa el valor de la ciudad como segundo argumento
              handleStateChange(fetchedData.state_country, fetchedData.city);
              form.setFieldsValue({
                state_country: fetchedData.state_country,
                city: fetchedData.city,
              });
            }
          }, 0);
        }
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();

    // Establecer las opciones de países
    const countryOptions: Option[] = countriesData.map((country) => ({
      value: country.name,
      label: country.name,
    }));
    setCountries(countryOptions);

    // Si ya hay un valor para país en el formulario, cargar los estados
    const initCountry = form.getFieldValue("country");
    if (initCountry) {
      handleCountryChange(initCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Manejo del cambio de valores en el formulario */
  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some(
      (key) => originalValues[key] !== allValues[key]
    );
    setHasChanges(isDifferent);

    if (changedValues.name_establishment) {
      setIsEmailDisabled(true);
      setIsNameDisabled(false);
    } else if (changedValues.email) {
      setIsNameDisabled(true);
      setIsEmailDisabled(false);
    } else {
      setIsNameDisabled(false);
      setIsEmailDisabled(false);
    }
  };

  const handleClearChanges = () => {
    form.setFieldsValue(originalValues);
    setHasChanges(false);
    setIsNameDisabled(false);
    setIsEmailDisabled(false);
  };

  /** Actualiza la lista de estados al seleccionar país */
  const handleCountryChange = (countryName: string) => {
    const selectedCountry = countriesData.find(
      (country) => country.name === countryName
    );

    if (selectedCountry && selectedCountry.states && selectedCountry.states.length) {
      const stateOptions: Option[] = selectedCountry.states.map((state) => ({
        value: state.name,
        label: state.name,
      }));
      setStates(stateOptions);
      setStatesDisabled(false);
    } else {
      setStates([]);
      setStatesDisabled(true);
    }

    setCities([]);
    setCitiesDisabled(true);
    // Se actualiza el valor del "state_country" y "city" en el formulario
    form.setFieldsValue({ state_country: undefined, city: undefined });
  };

  /**
   * Actualiza la lista de ciudades al seleccionar departamento (state_country).
   * @param stateName Nombre del departamento.
   * @param cityValue (Opcional) Valor de la ciudad a asignar.
   */
  const handleStateChange = (stateName: string, cityValue?: string) => {
    const countryName = form.getFieldValue("country");
    const selectedCountry = countriesData.find(
      (country) => country.name === countryName
    );
    const selectedState = selectedCountry?.states.find(
      (state) => state.name === stateName
    );

    if (selectedState && selectedState.cities && selectedState.cities.length) {
      const cityOptions: Option[] = selectedState.cities.map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(cityOptions);
      setCitiesDisabled(false);
    } else {
      setCities([]);
      setCitiesDisabled(true);
    }
    // Asigna el valor de la ciudad si se recibe un parámetro; de lo contrario, deja el campo sin valor.
    form.setFieldsValue({ city: cityValue ? cityValue : undefined });
  };

  /** Función al enviar el formulario */
  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    // Si se modificó el email, actualizar también el username
    if (changes.email) {
      changes.username = changes.email;
    }

    if (values.cuisine === "other") {
      changes.cuisine = values.cuisine_custom;
      delete changes.cuisine_custom;
    }

    if (values.countryCode !== originalValues.countryCode) {
      changes.countryCode = values.countryCode;
    }
    if (values.phone !== originalValues.phone) {
      changes.phone = values.phone;
    }

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
      return;
    }

    const shouldAlertName =
      changes.name_establishment &&
      changes.name_establishment !== originalValues.name_establishment;
    const shouldAlertEmail =
      changes.email && changes.email !== originalValues.email;

    const content = (
      <div>
        {shouldAlertName && (
          <p>
            El nombre del establecimiento ha cambiado. Esto puede generar inconsistencias en los
            datos.
          </p>
        )}
        {shouldAlertEmail && (
          <p>
            Si cambias el correo electrónico, este será el que utilizarás para iniciar sesión en
            futuras ocasiones.
          </p>
        )}
        <p>La página se actualizará una vez que se complete el cambio.</p>
      </div>
    );

    const submitChanges = async () => {
      try {
        setUpdating(true);
        await put("restaurant-onboarding", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: values.name_establishment,
          original_name_establishment: originalValues.name_establishment,
          ...changes,
        });
        message.success("Datos actualizados correctamente.");
        if (shouldAlertName || shouldAlertEmail) {
          window.location.reload();
        } else {
          fetchEstablishmentData();
          setHasChanges(false);
        }
      } catch (error) {
        message.error("Error al actualizar los datos del establecimiento.");
      } finally {
        setUpdating(false);
      }
    };

    if (shouldAlertName || shouldAlertEmail) {
      Modal.confirm({
        title: "Advertencia",
        content,
        onOk: submitChanges,
      });
    } else {
      submitChanges();
    }
  };

  const selectedCuisine = Form.useWatch("cuisine", form);

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {hasChanges && (
            <Button
              type="default"
              size="small"
              onClick={handleClearChanges}
              style={{
                marginLeft: "10px",
                borderRadius: "8px",
                background: "#f0f0f0",
                color: "#1890ff",
                border: "1px solid #1890ff",
                transition: "0.3s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "#e6f7ff";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "#f0f0f0";
              }}
            >
              Limpiar Cambios
            </Button>
          )}
        </div>
      }
      bordered={false}
      style={{
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: screens.xs ? "10px" : "10px",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <Spin size="large" />
          <Text style={{ marginTop: "20px" }}>Cargando datos del establecimiento...</Text>
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
          <Text
            type="danger"
            style={{
              display: "block",
              fontSize: "18px",
              marginTop: "20px",
            }}
          >
            Ocurrió un error al cargar los datos del establecimiento.
          </Text>
          <Button
            type="primary"
            size="large"
            style={{ marginTop: "20px", borderRadius: "8px" }}
            onClick={fetchEstablishmentData}
          >
            Reintentar
          </Button>
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          style={{ fontFamily: "Roboto, sans-serif" }}
        >
          {/* Fila 1: Nombre y Correo Electrónico */}
          <Row gutter={24}>
            <Col span={screens.md ? 12 : 24}>
              <Form.Item
                label="Nombre"
                name="name_establishment"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el nombre del establecimiento",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Ingrese el nombre del establecimiento"
                  style={{ borderRadius: "8px" }}
                  disabled={isNameDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={screens.md ? 12 : 24}>
              <Form.Item
                label="Correo Electrónico"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el correo electrónico",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Ingrese el correo electrónico"
                  style={{ borderRadius: "8px" }}
                  disabled={isEmailDisabled}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Sección para Dirección y Ubicación */}
          <Row gutter={24}>
            <Col span={screens.md ? 24 : 24}>
              <Form.Item
                label="Dirección"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese la dirección",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Ingrese la dirección"
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={screens.md ? 8 : 24}>
              <Form.Item
                label="País"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione el país",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Seleccione el país"
                  options={countries}
                  onChange={handleCountryChange}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={screens.md ? 8 : 24}>
              <Form.Item
                label="Estado / Departamento"
                name="state_country"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione el estado / departamento",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Seleccione el estado / departamento"
                  options={states}
                  disabled={statesDisabled}
                  onChange={(value) => handleStateChange(value)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={screens.md ? 8 : 24}>
              <Form.Item
                label="Ciudad"
                name="city"
                rules={[{ required: true, message: "Por favor seleccione la ciudad" }]}
              >
                <Select
                  size="large"
                  placeholder="Seleccione la ciudad"
                  options={cities}
                  disabled={citiesDisabled}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 2: Teléfono y (si no es e-commerce y no es medical/legal) Capacidad */}
          <Row gutter={24}>
            <Col span={screens.md ? (isEcommerce ? 24 : 12) : 24}>
              <Form.Item
                label="Teléfono"
                name="phone"
                rules={[
                  { required: true, message: "Por favor ingrese el teléfono" },
                  { pattern: /^\d+$/, message: "Por favor ingrese solo números" },
                ]}
              >
                <Input
                  size="large"
                  addonBefore={
                    <Form.Item name="countryCode" noStyle>
                      <Select style={{ width: 100, borderRadius: "8px" }} size="large">
                        <Select.Option value="+1">+1</Select.Option>
                        <Select.Option value="+44">+44</Select.Option>
                        <Select.Option value="+34">+34</Select.Option>
                        <Select.Option value="+57">+57</Select.Option>
                      </Select>
                    </Form.Item>
                  }
                  style={{ borderRadius: "8px" }}
                  placeholder="Ingrese el teléfono (solo dígitos)"
                />
              </Form.Item>
            </Col>
            {showRestaurantFields && (
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label="Capacidad"
                  name="capacity"
                  rules={[{ required: true, message: "Por favor ingrese la capacidad" }]}
                >
                  <Input
                    size="large"
                    placeholder="Ingrese la capacidad"
                    style={{ borderRadius: "8px" }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          {/* Fila 3: (si no es e-commerce y no es medical/legal) Mesas y Ambiente */}
          {showRestaurantFields && (
            <Row gutter={24}>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label="Mesas"
                  name="tables"
                  rules={[{ required: true, message: "Por favor ingrese el número de mesas" }]}
                >
                  <Input
                    size="large"
                    placeholder="Ingrese el número de mesas"
                    style={{ borderRadius: "8px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label="Ambiente"
                  name="atmosphere"
                  rules={[{ required: true, message: "Por favor seleccione el ambiente" }]}
                >
                  <Select
                    size="large"
                    placeholder="Seleccione el ambiente"
                    style={{ borderRadius: "8px" }}
                  >
                    {atmosphereOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* Fila 4: (si no es e-commerce y no es medical/legal) Tipo de Cocina y Rango de Precios */}
          {showRestaurantFields && (
            <Row gutter={24}>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item
                  label="Tipo de Cocina"
                  name="cuisine"
                  rules={[{ required: true, message: "Por favor seleccione el tipo de cocina" }]}
                >
                  <Select
                    size="large"
                    placeholder="Seleccione la categoría"
                    style={{ borderRadius: "8px" }}
                  >
                    {cuisineOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {selectedCuisine === "other" && (
                  <Form.Item
                    name="cuisine_custom"
                    label="Especifique el tipo de cocina"
                    rules={[
                      { required: true, message: "Por favor especifique el tipo de cocina" },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Ingrese el tipo de cocina"
                      style={{ borderRadius: "8px", marginTop: "8px" }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={screens.md ? 12 : 24}>
                <Form.Item label="Rango de Precios" required>
                  <Input.Group compact style={{ display: "flex", gap: "8px" }}>
                    <Form.Item
                      name="minPrice"
                      rules={[
                        { required: true, message: "Por favor ingrese el precio mínimo" },
                        { pattern: /^\d+$/, message: "Por favor ingrese un número válido" },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        size="large"
                        prefix="$"
                        placeholder="Mínimo"
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="maxPrice"
                      rules={[
                        { required: true, message: "Por favor ingrese el precio máximo" },
                        { pattern: /^\d+$/, message: "Por favor ingrese un número válido" },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        size="large"
                        prefix="$"
                        placeholder="Máximo"
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* Fila 5: Usuario (solo lectura) */}
          <Row gutter={24}>
            <Col span={screens.md ? 12 : 24}>
              <Form.Item
                label="Usuario"
                name="username"
                rules={[{ required: true, message: "Por favor ingrese el nombre de usuario" }]}
              >
                <Input
                  size="large"
                  placeholder="Ingrese el nombre de usuario"
                  style={{ borderRadius: "8px" }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 6: Descripción */}
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Descripción"
                name="description"
                rules={[{ required: true, message: "Por favor ingrese la descripción" }]}
              >
                <Input.TextArea
                  size="large"
                  placeholder="Ingrese una descripción del establecimiento"
                  autoSize={{ minRows: 3, maxRows: 8 }}
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Botón Guardar Cambios */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={updating}
              disabled={!hasChanges}
              style={{
                borderRadius: "8px",
                background: hasChanges
                  ? "linear-gradient(45deg, #1890ff, #1d39c4)"
                  : "gray",
                border: "none",
              }}
            >
              Guardar Cambios
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

export default GeneralData;