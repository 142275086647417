import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { AiFillMessage, AiFillLike } from "react-icons/ai";
import { RightOutlined } from "@ant-design/icons";
import { BiSupport } from "react-icons/bi";
import "./Home.css";
import { Card, Col, Row, message, Space, Spin, Typography } from "antd";
import EChart from "../../../components/chart/EChart";
import LineChart from "../../../components/chart/LineChart";
import Paragraph from "antd/es/typography/Paragraph";
import { get } from "../../../Api/http/httpClient";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

interface WelcomeProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const Home: React.FC<WelcomeProps> = ({ user_data, setTitleSection }) => {
  const [data, setData] = useState<any>(null);
  const [packageData, setPackageData] = useState<any>(null);
  const [dataError, setDataError] = useState(false);
  const [packageDataError, setPackageDataError] = useState(false);
  const isDataEmpty = !data || (Array.isArray(data) && data.length === 0);
  const isPackageDataEmpty = !packageData || Object.keys(packageData).length === 0;

  const getData = async () => {
    try {
      const url = `/dashboard?establishmentID=${user_data["custom:establishment_id"]}`;
      const response = await get(url);
      setData(response);
      setDataError(false);
    } catch (error) {
      console.error("Error obteniendo datos:", error);
      setDataError(true);
    }
  };

  const fetchPackageData = async () => {
    try {
      const response = await get(
        `packages-plans?establishment_id=${user_data["custom:establishment_id"]}&only_active=true`
      );
      if (response.answer) {
        setPackageData(response.item);
        setPackageDataError(false);
      } else {
        setPackageData(null);
        setPackageDataError(true);
      }
    } catch (error) {
      console.error("Error fetching package data:", error);
      setPackageDataError(true);
      message.error("Error al obtener los datos del perfil.");
    }
  };

  useEffect(() => {
    setTitleSection("Dashboard");
    getData();
    fetchPackageData();
  }, [setTitleSection]);

  // Datos para las tarjetas
  const currentMonthUniqueUsers = data?.monthly_comparison?.current_month_unique_users ?? 0;
  const currentMonthDiffPercentage = data?.monthly_comparison?.diff_percentage ?? 0;

  const yearMonthlyInteractions = data?.year_monthly_interactions || {};
  let userKeys = Object.keys(yearMonthlyInteractions);
  userKeys = userKeys.sort((a, b) => {
    const [monthA, yearA] = a.split(" ");
    const [monthB, yearB] = b.split(" ");
    const dateA = new Date(`${monthA} 1, ${yearA}`);
    const dateB = new Date(`${monthB} 1, ${yearB}`);
    return dateA.getTime() - dateB.getTime();
  });

  let lastValue = 0;
  let secondLastValue = 0;
  let differenceClients = 0;

  if (userKeys.length > 0) {
    const lastKey = userKeys[userKeys.length - 1];
    lastValue = yearMonthlyInteractions[lastKey] ?? 0;
  }
  if (userKeys.length > 1) {
    const secondLastKey = userKeys[userKeys.length - 2];
    secondLastValue = yearMonthlyInteractions[secondLastKey] ?? 0;
  }

  if (secondLastValue !== 0) {
    differenceClients = ((lastValue - secondLastValue) / secondLastValue) * 100;
  } else if (lastValue !== 0) {
    differenceClients = 100;
  } else {
    differenceClients = 0;
  }

  // Calcular el total de intervenciones humanas en el mes actual
  const currentMonthAgentTakeover = data?.monthly_comparison?.current_month_agent_takeover_counts || {};
  const currentMonthTakeoverTotal = Object.values(currentMonthAgentTakeover).reduce((acc: number, val: any) => acc + val, 0);

  // Calcular el total de intervenciones humanas en el mes anterior
  const previousMonthAgentTakeover = data?.monthly_comparison?.previous_month_agent_takeover_counts || {};
  const previousMonthTakeoverTotal = Object.values(previousMonthAgentTakeover).reduce((acc: number, val: any) => acc + val, 0);

  // Calcular la diferencia porcentual
  let takeoverDiffPercentage = 0;
  if (previousMonthTakeoverTotal !== 0) {
    takeoverDiffPercentage = ((currentMonthTakeoverTotal - previousMonthTakeoverTotal) / previousMonthTakeoverTotal) * 100;
  } else if (currentMonthTakeoverTotal !== 0) {
    takeoverDiffPercentage = 100;
  }

  const count = [
    {
      today: "Conversaciones Iniciadas",
      title: currentMonthUniqueUsers.toString(),
      persent: `${currentMonthDiffPercentage.toFixed(1)}%`,
      icon: <AiFillMessage />,
      bnb: "bnb2",
    },
    {
      today: "Clientes Atendidos",
      title: lastValue.toString(),
      persent: `${differenceClients.toFixed(1)}%`,
      icon: <FaUser />,
      bnb: "bnb2",
    },
    // {
    //   today: "Tasa de Interacción",
    //   title: "N/A", 
    //   icon: <AiFillLike />,
    // },
    {
      today: "Intervenciones Humanas",
      title: currentMonthTakeoverTotal.toString(),
      persent: `${takeoverDiffPercentage.toFixed(1)}%`,
      icon: <BiSupport />,
      bnb: "bnb2",
    },
  ];

  return (
    <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={8} //default '6'
            xl={8} //default '6'
            className="mb-24"
          >
            <Card bordered={false} className="criclebox">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.today}</span>
                    <Title level={3}>
                      {c.title} <small className={c.bnb}>{c.persent}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">{c.icon}</div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            {dataError ? (
              <Text type="danger">Error al cargar los datos del dashboard.</Text>
            ) : isDataEmpty ? (
              <Spin />
            ) : (
              <EChart data={data} />
            )}
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            {dataError ? (
              <Text type="danger">Error al cargar los datos del gráfico.</Text>
            ) : isDataEmpty ? (
              <Spin />
            ) : (
              <LineChart data={data} />
            )}
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                md={12}
                sm={24}
                lg={12}
                xl={14}
                className="mobile-24"
              >
                <div className="h-full col-content p-20">
                  <div className="ant-muse">
                    <Text>Acceso directo a la</Text>
                    <Title level={5}>Sección de Analíticas</Title>
                    <Paragraph className="lastweek mb-36">
                      En la sección de analíticas encontrarás más información acerca del rendimiento
                      de tu chatbot, datos de usuarios y estadísticas relevantes para tu negocio.
                    </Paragraph>
                  </div>
                  <div className="card-footer">
                    <Link className="icon-move-right" to="/dashboard/analytics">
                      Ir a Analíticas <RightOutlined />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox card-info-2 h-full">
            <div className="gradent h-full col-content">
              <div className="card-content">
                {packageDataError ? (
                  <Text type="danger">Error al cargar los datos del plan actual.</Text>
                ) : isPackageDataEmpty ? (
                  <Spin />
                ) : (
                  <>
                    <Title level={3}>
                      Tu Plan Actual: {packageData?.plan_name ?? "Sin plan"}
                    </Title>
                    <p>
                      Te quedan <strong>{packageData?.remaining_messages ?? 0}</strong>{" "}
                      mensajes de un total de{" "}
                      <strong>{packageData?.initial_messages ?? 0}</strong>.{" "}
                      {packageData?.expiry_date && (
                        <>
                          Este plan expira el{" "}
                          <strong>
                            {new Date(packageData.expiry_date).toLocaleDateString()}
                          </strong>
                          .
                        </>
                      )}
                    </p>
                    <div className="card-footer">
                      <Link className="icon-move-right" to="/dashboard/user-profile">
                        Ir a Perfil <RightOutlined />
                      </Link>
                    </div>
                  </>
                )}
              </div>

              <div className="card-content" style={{ paddingTop: "15px" }}>
                <Title level={3}>Integración con WhatsApp</Title>
                <p>
                  Si todavía no lo has realizado, puedes hacer la integración con WhatsApp.
                </p>
              
                <div className="card-footer">
                  <Link className="icon-move-right" to="/dashboard/whatsapp-integration">
                    Integrar con WhatsApp <RightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

    </Space>
  );
};

export default Home;
