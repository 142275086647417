import { parseMonthYear, sortByChronology } from "../../../utils/year_monthly_interactions";

// Helper para convertir el índice de mes (1 a 12) a su abreviación.
const MONTHS_LABELS = [
  "",    // index 0 no se usa
  "Ene", // index 1
  "Feb", // index 2
  "Mar", // index 3
  "Abr", // index 4
  "May", // index 5
  "Jun", // index 6
  "Jul", // index 7
  "Ago", // index 8
  "Sep", // index 9
  "Oct", // index 10
  "Nov", // index 11
  "Dic", // index 12
];

export function getLineChartConfig(data: any) {
  const monthlyUsers = data?.year_monthly_users || {};
  const monthlyInteractions = data?.year_monthly_interactions || {};

  // Combinar todas las llaves de ambos objetos
  //    para que no se pierda ningún mes existente en uno u otro.
  const allKeys = new Set([
    ...Object.keys(monthlyUsers), 
    ...Object.keys(monthlyInteractions),
  ]);
  let keys = Array.from(allKeys);

  //Ordenar cronológicamente (de más antiguo a más reciente).
  keys = sortByChronology(keys);

  // Construir las categorías (eje X) y los datos para cada serie.
  const categories: string[] = [];
  const usersData: number[] = [];
  const interactionsData: number[] = [];

  keys.forEach((key) => {
    const { monthIndex } = parseMonthYear(key);
    const label = `${MONTHS_LABELS[monthIndex]}`;

    categories.push(label);
    usersData.push(monthlyUsers[key] || 0);
    interactionsData.push(monthlyInteractions[key] || 0);
  });

  // Manejo de noData: si no hay llaves, mostrar "No hay datos"
  if (keys.length === 0) {
    return {
      series: [],
      options: {
        chart: {
          type: "area" as const,
          width: "100%",
          height: 350,
          toolbar: { show: false },
        },
        noData: {
          text: "No hay datos",
          align: "center" as "center",
          verticalAlign: "middle" as "middle",
          style: {
            color: "#fff",
            fontSize: "14px",
          },
        },
              
      },
    };
  }

  return {
    series: [
      {
        name: "Usuarios",
        data: usersData,
        offsetY: 0,
      },
      {
        name: "Clientes Atendidos",
        data: interactionsData,
        offsetY: 0,
      },
    ],
    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area" as const,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth" as const,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            colors: ["#8c8c8c"],
          },
        },
      },
      xaxis: {
        categories, // ejes con los meses dinámicos
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 300,
            colors: new Array(categories.length).fill("#8c8c8c"),
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  };
}