import React, { useState, useEffect, useRef } from "react";
import { post, get } from "../../Api/http/httpClient";
import moment from "moment";
import {
  Input,
  Button,
  Avatar,
  Skeleton,
  message as antdMessage,
  Spin,
  Alert,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import imageProfile from "../../imgs/OIG4.jpg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./Chat.module.css";

interface Message {
  message: string;
  timestamp: string;
  role: "user" | "assistant";
  loading?: boolean;
}

interface AIResponse {
  answer: boolean;
  message: string;
}

interface ChatAIProps {
  user_data: {
    "custom:establishment_id": string;
    family_name: string;
    "custom:plan": string;
  };
}

const Chat: React.FC<ChatAIProps> = ({ user_data }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [botTyping, setBotTyping] = useState<boolean>(false);
  const chatUuid = user_data["custom:establishment_id"];
  const [remainingFreeMessages, setRemainingFreeMessages] = useState<number | null>(null);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await get(
          `/restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
        );
        setRemainingFreeMessages(response.item.remaining_free_messages);

        const chatHistoryResponse = await get(
          `/chat-ai?establishmentID=${user_data["custom:establishment_id"]}&chat_uuid=${chatUuid}`
        );

        if (chatHistoryResponse.answer && chatHistoryResponse.data) {
          const combinedMessages: Message[] = chatHistoryResponse.data.history
            .filter((item: any) => item.message !== "ß-Mensaje enviado por la plataforma-ß")
            .map((item: any) => ({
              message: item.message,
              timestamp: item.timestamp,
              role: item.role === "assistant" || item.role === "agent" ? "assistant" : "user",
            }));

          setMessages(
            combinedMessages.length > 0
              ? combinedMessages
              : [
                  {
                    message: "¡Hola! Soy tu asistente IA. Estoy aquí para ayudarte con tu inventario.",
                    timestamp: moment().toISOString(),
                    role: "assistant",
                  },
                ]
          );
        }
      } catch (error) {
        antdMessage.error("Error al cargar los datos del establecimiento o el historial de chat.");
      } finally {
        setLoadingMessages(false);
      }
    };

    fetchInitialData();
  }, [user_data, chatUuid]);

  const getResponseAI = async (message: string) => {
    const requestBody = {
      question: message,
      establishment_id: user_data["custom:establishment_id"],
      name_establishment: user_data.family_name,
      chat_uuid: chatUuid,
    };

    try {
      const data: AIResponse = await post("/chat-ai", requestBody);

      let text: string;
      if (data.answer) {
        text = data.message;
        setRemainingFreeMessages((prev) => (prev !== null && prev > 0 ? prev - 1 : 0));
      } else {
        text = "Lo siento, no puedo responder en este momento.";
        antdMessage.error("Error al obtener la respuesta del bot de IA.");
      }

      const botMsg: Message = {
        message: text,
        timestamp: moment().toISOString(),
        role: "assistant",
      };

      // Reemplaza el mensaje de carga por el mensaje real del bot.
      setMessages((prevMessages) => [...prevMessages.slice(0, -1), botMsg]);
    } catch (error) {
      const botMsg: Message = {
        message: "Lo siento, no puedo responder en este momento.",
        timestamp: moment().toISOString(),
        role: "assistant",
      };

      setMessages((prevMessages) => [...prevMessages.slice(0, -1), botMsg]);
      antdMessage.error("Error al obtener la respuesta del bot de IA.");
    } finally {
      setBotTyping(false);
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "" && remainingFreeMessages && remainingFreeMessages > 0) {
      const userMsg: Message = {
        message: newMessage,
        timestamp: moment().toISOString(),
        role: "user",
      };
      setMessages([...messages, userMsg]);
      setNewMessage("");
      setBotTyping(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: "",
          timestamp: moment().toISOString(),
          role: "assistant",
          loading: true,
        },
      ]);
      getResponseAI(newMessage);
    } else if (remainingFreeMessages === 0) {
      antdMessage.warning("No tienes mensajes gratuitos disponibles.");
    }
  };

  const getRemainingMessagesStyle = () => {
    if (remainingFreeMessages !== null) {
      if (remainingFreeMessages >= 15) {
        return { backgroundColor: "green", color: "white" };
      } else if (remainingFreeMessages >= 8) {
        return { backgroundColor: "orange", color: "white" };
      } else {
        return { backgroundColor: "red", color: "white" };
      }
    }
    return {};
  };

  // Función para formatear el contenido del mensaje usando ReactMarkdown y remark-gfm
  const formatMessage = (msg: Message) => {
    const imageRegex = /\.(jpeg|jpg|gif|png)$/i;
    // Si el mensaje es una imagen, se renderiza la imagen
    if (imageRegex.test(msg.message)) {
      return (
        <img
          src={msg.message}
          alt="Imagen enviada"
          className={styles.chatImage}
        />
      );
    }

    // Para otros textos, se utiliza ReactMarkdown para renderizar listas y demás formatos Markdown.
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ node, ...props }) => (
            <img
              src={props.src || ""}
              alt={props.alt || "Imagen enviada"}
              style={{ maxWidth: "100%", borderRadius: "8px", marginTop: "10px" }}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "5px" }} {...props} />
          ),
          p: ({ node, ...props }) => (
            <p style={{ margin: "5px 0" }} {...props} />
          ),
        }}
      >
        {msg.message}
      </ReactMarkdown>
    );
  };

  useEffect(() => {
    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    };

    window.addEventListener("keydown", handleEnterPress);
    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [newMessage, remainingFreeMessages]);

  return (
    <div className={styles.wrapper}>
      {user_data["custom:plan"] === "0" && (
        <Alert
          type="info"
          message="Chat de Demostración"
          description={
            <span>
              Estás en un entorno de prueba. Para integrar WhatsApp y acceder a todas
              las funciones, adquiere un plan premium.{" "}
              <Button
                type="link"
                onClick={() => (window.location.href = "/plans")}
                style={{ padding: 0 }}
              >
                Adquirir Paquete
              </Button>
            </span>
          }
          showIcon
          className={styles.compactInfo}
        />
      )}

      <div className={styles.chatContainer}>
        <div className={styles.chatHeader}>
          <div className={styles.chatHeaderDetails}>
            <Avatar src={imageProfile} size="large" className={styles.avatar} />
            <div className={styles.chatName}>Bot de IA</div>
          </div>
          <div
            className={styles.remainingMessagesCard}
            style={getRemainingMessagesStyle()}
          >
            {remainingFreeMessages !== null ? (
              `Mensajes restantes: ${remainingFreeMessages}`
            ) : (
              <Spin size="small" />
            )}
          </div>
        </div>

        <div className={styles.chatMessages}>
          {loadingMessages ? (
            <div className={styles.initialMessageLoading}>
              <Spin size="large" />
              <p className={styles.loadingText}>Cargando historial...</p>
            </div>
          ) : (
            messages.map((msg, index) => (
              <div
                key={`${msg.message}-${msg.timestamp}-${index}`}
                className={`${styles.chatMessage} ${
                  msg.role === "assistant" ? styles.received : styles.sent
                }`}
              >
                <div
                  className={`${styles.chatMessageContent} ${
                    msg.role === "assistant" ? styles.receivedContent : styles.sentContent
                  }`}
                >
                  {msg.loading ? (
                    <Skeleton.Input active style={{ width: 150 }} />
                  ) : (
                    formatMessage(msg)
                  )}
                </div>
                <div className={styles.chatMessageTime}>
                  {moment(msg.timestamp).format("HH:mm")}
                </div>
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>

        <div className={styles.chatInput}>
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Escribe un mensaje"
            className={styles.chatInputText}
            onPressEnter={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
            disabled={
              botTyping ||
              (remainingFreeMessages !== null && remainingFreeMessages === 0) ||
              loadingMessages
            }
            suffix={
              <Button
                type="primary"
                shape="circle"
                icon={<SendOutlined />}
                onClick={handleSendMessage}
                disabled={
                  botTyping ||
                  (remainingFreeMessages !== null && remainingFreeMessages === 0) ||
                  loadingMessages
                }
                className={styles.antBtn}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;