import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  DatePicker,
  Table,
  Spin,
  Typography,
  message,
  Collapse
} from "antd";
import { InfoCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { get } from "../../Api/http/httpClient";
import { Chart } from "primereact/chart";
import dayjs from "dayjs";
import "./Analytics.css";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Panel } = Collapse;

interface AnalyticsProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const Analytics: React.FC<AnalyticsProps> = ({ user_data, setTitleSection }) => {
  const [loading, setLoading] = useState(true);
  const [topicsByWeek, setTopicsByWeek] = useState<any[]>([]);
  const [analyticsData, setAnalyticsData] = useState<any[]>([]);
  const [selectedDates, setSelectedDates] = useState<[Date, Date]>([
    dayjs().subtract(7, "days").toDate(),
    dayjs().toDate(),
  ]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  useEffect(() => {
    setTitleSection("Análisis");
  }, [setTitleSection]);

  const fetchAnalyticsData = async (startDate: Date, endDate: Date) => {
    setLoading(true);
    try {
      const url = `/analytics?establishmentID=${user_data["custom:establishment_id"]}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
      const response = await get(url);

      if (response.answer) {
        const items = response.items || [];
        let totalInteractions = 0;
        let consolidatedUsers: any[] = [];
        let topicsPerWeek: any[] = [];

        items.forEach((item: any) => {
          totalInteractions += item.total_interactions;

          const parsedMessages = safeJsonParse(item.messages_per_user || "[]");
          const parsedTopics = safeJsonParse(item.topics || "{}");

          consolidatedUsers = consolidatedUsers.concat(
            parsedMessages.map((user: any) => ({
              chat_uuid: user.user,
              interactions: user.count,
            }))
          );

          topicsPerWeek.push({
            week: dayjs(item.week_start_date).format("YYYY-MM-DD"),
            topics: Object.entries(parsedTopics).map(([topic, details]: any) => ({
              type: topic,
              value: details.percentage,
            })),
          });
        });

        setTotalInteractions(totalInteractions);
        setAnalyticsData(consolidatedUsers);
        setTopicsByWeek(topicsPerWeek);
      } else {
        setAnalyticsData([]);
        setTopicsByWeek([]);
        setTotalInteractions(0);
        message.warning("No se encontraron datos para el rango seleccionado.");
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
      message.error("Error al obtener datos analíticos.");
    } finally {
      setLoading(false);
    }
  };

  const safeJsonParse = (data: string): any => {
    try {
      const validJson = data.replace(/'/g, '"').trim();
      return JSON.parse(validJson);
    } catch (error) {
      console.error("Error parsing JSON:", error, "Data:", data);
      return [];
    }
  };

  useEffect(() => {
    fetchAnalyticsData(selectedDates[0], selectedDates[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      const startDate = dates[0].toDate();
      const endDate = dates[1].toDate();
      setSelectedDates([startDate, endDate]);
      fetchAnalyticsData(startDate, endDate);
    }
  };

  const handleTableChange = (paginationConfig: any) => {
    setPagination(paginationConfig);
  };

  const columns = [
    {
      title: "# Teléfono",
      dataIndex: "chat_uuid",
      key: "chat_uuid",
    },
    {
      title: "Interacciones",
      dataIndex: "interactions",
      key: "interactions",
    },
  ];

  return (
    <Row justify="center" style={{ marginTop: "20px", padding: "20px" }}>
      <Col xs={24} sm={24} md={20} lg={16}>
        <Card title="Análisis" bordered={false}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Row justify="center" gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Text strong style={{ textAlign: "center", marginBottom: "8px" }}>
                    Seleccionar Rango de Fechas:
                  </Text>
                  <RangePicker
                    value={[dayjs(selectedDates[0]), dayjs(selectedDates[1])]}
                    onChange={handleDateChange}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>

              {/* Sección explicativa sobre la analítica con Collapse */}
              <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Collapse
                    bordered={false}
                    style={{ background: "#f9f9f9", borderRadius: "8px" }}
                  >
                    <Panel
                      header={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InfoCircleOutlined style={{ marginRight: 8, color: "#1890ff" }} />
                          <Text strong>¿Cómo interpretar estos datos?</Text>
                        </div>
                      }
                      key="1"
                    >
                      <p style={{ marginBottom: "10px" }}>
                        Esta sección te presenta información clave sobre las interacciones con tus clientes mediante WhatsApp:
                      </p>
                      <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "8px"}}>
                            <div>
                              <strong>Generación Automática de Datos:</strong> Cada semana, el lunes entre las 5 y 6 de la mañana, se genera automáticamente un informe que recopila y procesa estas métricas. Esto asegura que siempre tengas datos actualizados para analizar y tomar decisiones informadas.
                            </div>
                        </li>
                        <li style={{ marginBottom: "8px" }}>
                          <strong>Total de Interacciones:</strong> Muestra cuántos mensajes entrantes se han intercambiado durante el periodo seleccionado.
                        </li>
                        <li style={{ marginBottom: "8px" }}>
                          <strong>Interacciones por Usuario:</strong> Una tabla para visualizar las interacciones por cliente en el periodo establecido.
                        </li>
                        <li style={{ marginBottom: "8px" }}>
                          <strong>Distribución de Temas:</strong> Un gráfico que resalta los temas más frecuentes en las conversaciones, separados por semana, permitiéndote comprender las necesidades más comunes de tus clientes.
                        </li>                        
                      </ul>
                      <p>
                        Utiliza esta información para ajustar tu estrategia de atención al cliente, descubrir tendencias y mejorar la experiencia general de tus usuarios.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
              {/* Fin de la sección explicativa */}

              <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Card title="Resumen de Interacciones" bordered={false}>
                    <Text strong>Total de Interacciones: </Text>
                    <Text>{totalInteractions}</Text>
                  </Card>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Table
                    dataSource={analyticsData}
                    columns={columns}
                    rowKey="chat_uuid"
                    pagination={{
                      ...pagination,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20", "30"],
                    }}
                    onChange={handleTableChange}
                  />
                </Col>
              </Row>

              {topicsByWeek.map((weekData, index) => (
                <Row key={index} style={{ marginTop: "20px" }}>
                  <Col xs={24}>
                    <Card
                      title={`Distribución de Temas - Semana del ${weekData.week}`}
                      bordered={false}
                    >
                      <div style={{ height: "300px" }}>
                        <Chart
                          type="bar"
                          data={{
                            labels: weekData.topics.map(
                              (t: { type: string; value: number }) => t.type
                            ),
                            datasets: [
                              {
                                label: "Porcentaje",
                                data: weekData.topics.map(
                                  (t: { type: string; value: number }) => t.value
                                ),
                                backgroundColor: weekData.topics.map(
                                  (_: any, idx: number) =>
                                    ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"][
                                      idx % 6
                                    ]
                                ),
                              },
                            ],
                          }}
                          options={{
                            indexAxis: "y",
                            plugins: {
                              legend: { display: false },
                              tooltip: {
                                callbacks: {
                                  label: (context: { raw: number }) => `${context.raw}%`,
                                },
                              },
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                              x: {
                                title: { display: true, text: "Porcentaje" },
                              },
                              y: {
                                title: { display: true, text: "Temas" },
                              },
                            },
                          }}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Analytics;
