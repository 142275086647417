import React, { useState, useEffect } from "react";
import { Button, Checkbox, TimePicker, Alert } from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  ButtonGroup,
  CheckboxButton,
  CheckboxContainer,
} from "../landingpage/styledComponents/Buttons";

interface BusinessHoursProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function BusinessHours({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
}: BusinessHoursProps) {
  // Estado para los días de apertura
  const [openDays, setOpenDays] = useState<string[]>(
    initialValues.openDays || []
  );

  // Estados para las horas de apertura y cierre
  const [openTime, setOpenTime] = useState<Dayjs | null>(
    initialValues.openTime ? dayjs(initialValues.openTime, "h:mm A") : null
  );
  const [closeTime, setCloseTime] = useState<Dayjs | null>(
    initialValues.closeTime ? dayjs(initialValues.closeTime, "h:mm A") : null
  );

  // Estados para horarios especiales
  const [hasSpecialHours, setHasSpecialHours] = useState(
    initialValues.specialHours || false
  );
  const [specialOpenTime, setSpecialOpenTime] = useState<Dayjs | null>(
    initialValues.specialOpenTime
      ? dayjs(initialValues.specialOpenTime, "h:mm A")
      : null
  );
  const [specialCloseTime, setSpecialCloseTime] = useState<Dayjs | null>(
    initialValues.specialCloseTime
      ? dayjs(initialValues.specialCloseTime, "h:mm A")
      : null
  );

  // Estados para mensajes de error
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const days = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  // Manejar la selección de días
  const handleDayChange = (checkedValues: any) => {
    setOpenDays(checkedValues);
  };

  // Validar el formulario antes de enviar
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    if (openDays.length === 0) {
      newErrors.openDays = "Por favor seleccione al menos un día de apertura.";
    }

    if (!openTime) {
      newErrors.openTime = "Por favor ingrese la hora de apertura.";
    }

    if (!closeTime) {
      newErrors.closeTime = "Por favor ingrese la hora de cierre.";
    }

    if (hasSpecialHours) {
      if (!specialOpenTime) {
        newErrors.specialOpenTime =
          "Por favor ingrese la hora de apertura especial.";
      }

      if (!specialCloseTime) {
        newErrors.specialCloseTime =
          "Por favor ingrese la hora de cierre especial.";
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Manejar el envío del formulario
  const handleSubmit = () => {
    if (validateForm()) {
      const formattedValues = {
        openDays,
        openTime: openTime ? openTime.format("h:mm A") : "",
        closeTime: closeTime ? closeTime.format("h:mm A") : "",
        specialHours: hasSpecialHours,
        specialOpenTime: specialOpenTime
          ? specialOpenTime.format("h:mm A")
          : "",
        specialCloseTime: specialCloseTime
          ? specialCloseTime.format("h:mm A")
          : "",
      };
      updateFormData(formattedValues);
      nextStep();
    }
  };

  return (
    <div>
      {/* Días de apertura */}
      <div style={{ marginBottom: 24 }}>
        <label style={{ display: "block", marginBottom: 8 }}>
          Días de apertura
        </label>
        <Checkbox.Group value={openDays} onChange={handleDayChange}>
          <ButtonGroup $justify="flex-start">
            {days.map((day) => (
              <CheckboxContainer key={day}>
                <Checkbox value={day} />
                <CheckboxButton>
                  <label>
                    <span>{day}</span>
                  </label>
                </CheckboxButton>
              </CheckboxContainer>
            ))}
          </ButtonGroup>
        </Checkbox.Group>
        {errors.openDays && (
          <Alert
            message={errors.openDays}
            type="error"
            showIcon
            style={{ marginTop: 8 }}
          />
        )}
      </div>

      {/* Horas de apertura y cierre */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 24,
        }}
      >
        <div style={{ width: "48%" }}>
          <label>Hora de apertura</label>
          <TimePicker
            format="h:mm A"
            use12Hours
            size="large"
            placeholder="Seleccionar hora de apertura"
            style={{ width: "100%" }}
            allowClear={false}
            value={openTime}
            onChange={(time) => setOpenTime(time)}
          />
          {errors.openTime && (
            <Alert
              message={errors.openTime}
              type="error"
              showIcon
              style={{ marginTop: 8 }}
            />
          )}
        </div>
        <div style={{ width: "48%" }}>
          <label>Hora de cierre</label>
          <TimePicker
            format="h:mm A"
            use12Hours
            size="large"
            placeholder="Seleccionar hora de cierre"
            style={{ width: "100%" }}
            allowClear={false}
            value={closeTime}
            onChange={(time) => setCloseTime(time)}
          />
          {errors.closeTime && (
            <Alert
              message={errors.closeTime}
              type="error"
              showIcon
              style={{ marginTop: 8 }}
            />
          )}
        </div>
      </div>

      {/* Checkbox para horarios especiales */}
      <div style={{ marginBottom: 24 }}>
        <Checkbox
          checked={hasSpecialHours}
          onChange={(e) => setHasSpecialHours(e.target.checked)}
        >
          Horarios especiales para fines de semana y festivos
        </Checkbox>
      </div>

      {/* Horarios especiales si se selecciona la casilla */}
      {hasSpecialHours && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <label>Hora de apertura (Horarios especiales)</label>
              <TimePicker
                format="h:mm A"
                use12Hours
                size="large"
                placeholder="Seleccionar hora de apertura especial"
                style={{ width: "100%" }}
                allowClear={false}
                value={specialOpenTime}
                onChange={(time) => setSpecialOpenTime(time)}
              />
              {errors.specialOpenTime && (
                <Alert
                  message={errors.specialOpenTime}
                  type="error"
                  showIcon
                  style={{ marginTop: 8 }}
                />
              )}
            </div>
            <div style={{ width: "48%" }}>
              <label>Hora de cierre (Horarios especiales)</label>
              <TimePicker
                format="h:mm A"
                use12Hours
                size="large"
                placeholder="Seleccionar hora de cierre especial"
                style={{ width: "100%" }}
                allowClear={false}
                value={specialCloseTime}
                onChange={(time) => setSpecialCloseTime(time)}
              />
              {errors.specialCloseTime && (
                <Alert
                  message={errors.specialCloseTime}
                  type="error"
                  showIcon
                  style={{ marginTop: 8 }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Botones de navegación */}
      <div>
        <Button onClick={prevStep} style={{ marginRight: 8 }} size="large">
          Anterior
        </Button>
        <Button type="primary" onClick={handleSubmit} size="large">
          Siguiente
        </Button>
      </div>
    </div>
  );
}
