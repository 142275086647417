import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, Spin, Empty, Card, Row, Col, Tooltip } from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  ReloadOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import Highlighter from "react-highlight-words";
import useDebounce from "../../utils/useDebounce";

const TableComponent = ({
  data,
  headers_table,
  loading,
  setSearch,
  setAction,
  totalPages,
  setPage,
  page,
  setItemsPerPage,
  itemsPerPage,
  setSortOrder,
  setSortColumn,
  deleting,
  isViewMode = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [generalSearch, setGeneralSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortInfo, setSortInfo] = useState({ column: null, order: null }); // Estado para almacenar la columna y el orden

  const debouncedSearchText = useDebounce(searchText, 500);
  const debouncedGeneralSearch = useDebounce(generalSearch, 500);

  useEffect(() => {
    setSearch(debouncedGeneralSearch);
  }, [debouncedGeneralSearch]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} className="custom-table-filter-dropdown">
        <Input
          placeholder={`Buscar ${headers_table[dataIndex] || dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
          className="custom-table-input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="custom-table-search-button"
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="custom-table-reset-button"
          >
            Resetear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "#1890ff" : undefined }}
        className="custom-table-filter-icon"
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[debouncedSearchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
          className="custom-table-highlighter"
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleGeneralSearch = (e) => {
    setGeneralSearch(e.target.value);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setItemsPerPage(pageSize);
    setAction({ type: "reload" });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      setSortOrder(sorter.order);
      setSortColumn(sorter.columnKey);
      setSortInfo({ column: sorter.columnKey, order: sorter.order }); // Almacena la columna y el orden
      console.log("Columna ordenada:", sorter.columnKey);
      console.log("Orden:", sorter.order);
    } else {
      setSortOrder(null);
      setSortColumn(null);
      setSortInfo({ column: null, order: null });
    }
  };

  const columns = Object.keys(headers_table).map((key) => ({
    title: headers_table[key],
    dataIndex: key,
    key: key,
    sorter: (a, b) => {
      if (typeof a[key] === "string") {
        return a[key].localeCompare(b[key]);
      }
      return a[key] - b[key];
    },
    onCell: () => {
      return {
        style: {
          minWidth: 150,
          maxWidth: 300,
        },
      };
    },
  }));

  columns.push({
    title: "Acciones",
    key: "actions",
    render: (text, record) => (
      <Space size="small" className="custom-table-actions">
        {isViewMode ? (
          <Tooltip title="Ver">
            <Button
              icon={<EyeOutlined />}
              onClick={() => setAction({ type: "view", data: record })}
              type="primary"
              className="custom-table-view-button"
            >
              {/* Ver */}
            </Button>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Editar">
              <Button
                icon={<EditOutlined />}
                onClick={() => setAction({ type: "edit", data: record })}
                type="text"
                disabled={!!deleting}
                className="custom-table-edit-button"
              >
                {/* Editar */}
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => setAction({ type: "delete", data: record })}
                type="text"
                loading={deleting === record.id}
                disabled={!!deleting && deleting !== record.id}
                className="custom-table-delete-button"
              >
                {/* {deleting === record.id ? "Eliminando..." : "Eliminar"} */}
              </Button>
            </Tooltip>
          </>
        )}
      </Space>
    ),
  });

  // Componente personalizado para mostrar "Sin datos" en la caja vacía
  const EmptyText = () => <Empty description="Sin datos" />;

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            style={{ padding: 20}}
          >
            <Space
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className="custom-table-top-actions"
            >
              <Input
                placeholder="Buscar..."
                value={generalSearch}
                onChange={handleGeneralSearch}
                style={{ maxWidth: "300px" }}
                className="custom-table-general-search"
                prefix={<SearchOutlined />}
              />
              <Space>
                <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={() => setAction({ type: "reload" })}
                  disabled={!!deleting}
                  className="custom-table-reload-button"
                >
                  Actualizar
                </Button>
                {!isViewMode && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setAction({ type: "create" })}
                    disabled={!!deleting}
                    className="custom-table-add-button"
                  >
                    Agregar
                  </Button>
                )}
              </Space>
            </Space>
            <Spin spinning={loading} className="custom-table-spin">
              <div className="table-responsive">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="id"
                  locale={{ emptyText: <EmptyText /> }}
                  pagination={{
                    current: page,
                    pageSize: itemsPerPage,
                    onChange: handlePageChange,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "50"],
                    total: totalPages * itemsPerPage,
                  }}
                  onChange={handleTableChange}
                  className="ant-border-space"
                  size="middle"
                />
              </div>
            </Spin>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TableComponent;
