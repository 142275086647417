import { signIn, signOut } from "@aws-amplify/auth";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Grid,
  Space,
  message,
} from "antd";
import { post } from "../../Api/http/httpClient";
import { getCurrentUser } from "@aws-amplify/auth";

const serviceOptions = [
  { label: "Wi-Fi", value: "wifi" },
  { label: "Terraza", value: "terrace" },
  { label: "Música en vivo", value: "live-music" },
  { label: "Zona de fumadores", value: "smoking-area" },
];

const paymentMethodOptions = [
  { label: "Efectivo", value: "cash" },
  { label: "Tarjeta de crédito", value: "credit-card" },
  { label: "Tarjeta de débito", value: "debit-card" },
  { label: "Pago móvil", value: "mobile-payment" },
];

const cuisineOptions = [
  { label: "Italiana", value: "italian" },
  { label: "Mexicana", value: "mexican" },
  { label: "Fusión", value: "fusion" },
  { label: "Otra", value: "other" },
];

const atmosphereOptions = [
  { label: "Formal", value: "formal" },
  { label: "Casual", value: "casual" },
  { label: "Familiar", value: "family" },
  { label: "Romántico", value: "romantic" },
];

const businessTypeOptions: Record<string, { value: string; label: string }[]> =
  {
    restaurant: [
      { value: "restaurant", label: "Restaurante" },
      { value: "bar", label: "Bar" },
      { value: "nightclub", label: "Discoteca" },
    ],
    medical: [
      { value: "medical-office", label: "Consultorio médico" },
      { value: "legal-office", label: "Consultorio jurídico" },
    ],
    eCommerce: [{ value: "e-commerce", label: "E-commerce" }],
  };

interface FinalReviewProps {
  formData: Record<string, any>;
  setStep: (step: number) => void;
  prevStep: () => void;
  businessModel: string;
  onLoadingChange: (isLoading: boolean) => void; // Nuevo prop para controlar el modal
}

export default function FinalReview({
  formData,
  setStep,
  prevStep,
  businessModel,
  onLoadingChange,
}: FinalReviewProps): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));
  }, []);

  const handleSubmit = async () => {
    // Mostramos el modal de carga
    onLoadingChange(true);

    const dataToSend = {
      ...formData,
      cuisine:
        formData.cuisine === "other" ? formData.otherCuisine : formData.cuisine,
    };

    try {
      const response = await post("/restaurant-onboarding", dataToSend);

      if (response.answer) {
        if (isAuthenticated) {
          await signOut();
          message.info("Se ha cerrado la sesión del usuario actual.");
        }

        await signIn({
          username: formData.email,
          password: formData.password,
        });

        message.success("Registro exitoso. ¡Bienvenido a la plataforma!");
        window.location.href = "/dashboard";
      } else {
        message.error(
          "Hubo un error al enviar el formulario. Inténtalo de nuevo."
        );
      }
    } catch (error) {
      console.error(error);
      message.error("Hubo un error al enviar el formulario. Inténtalo de nuevo.");
    } finally {
      // Ocultamos el modal de carga
      onLoadingChange(false);
    }
  };

  const capitalizeFirstLetter = (text: string, type: string) => {
    const options = businessTypeOptions[type];
    if (options) {
      const match = options.find((option) => option.value === text);
      if (match) return match.label;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const formatPriceRange = (min: string, max: string) => {
    if (!min || !max) return "No especificado";
    return `$${parseInt(min).toLocaleString("es-ES")} - $${parseInt(
      max
    ).toLocaleString("es-ES")}`;
  };

  const renderBusinessSpecificFields = () => {
    if (businessModel === "e-commerce") {
      return (
        <>
          <Descriptions.Item label="Tiene puntos físicos">
            {formData.hasPhysicalPoints ? "Sí" : "No"}
          </Descriptions.Item>
          {formData.hasPhysicalPoints && (
            <Descriptions.Item label="Puntos físicos">
              {formData.physicalPoints.map((point: any, index: number) => (
                <div key={index}>
                  Dirección: {point.address}, Teléfono: {point.phone}, Horarios:{" "}
                  {point.schedule.join(", ")}, Días: {point.days.join(", ")}
                </div>
              ))}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Cantidad de categorías">
            {formData.categories.length} categoría(s):{" "}
            {formData.categories.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Productos por categoría">
            {Object.entries(formData.products).map(
              ([category, products]: any) => (
                <div key={category}>
                  <strong>{category}:</strong> {products.length} producto(s)
                </div>
              )
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Políticas de devolución">
            {formData.returnPolicies}
          </Descriptions.Item>
          <Descriptions.Item label="Descuentos">
            {formData.discounts}
          </Descriptions.Item>
        </>
      );
    }

    if (businessModel === "medical") {
      return (
        <>
          <Descriptions.Item label="Tipo de consulta">
            {capitalizeFirstLetter(formData.consultationType, "medical")}
          </Descriptions.Item>
          <Descriptions.Item label="Días de apertura">
            {formData.openDays.length} día(s): {formData.openDays.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Horario">
            {formData.openTime} - {formData.closeTime}
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de categorías de servicios">
            {formData.categories.length} categoría(s):{" "}
            {formData.categories.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Servicios por categoría">
            {Object.entries(formData.services).map(
              ([category, services]: any) => (
                <div key={category}>
                  <strong>{category}:</strong> {services.length} servicio(s)
                </div>
              )
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Política de reservas">
            {formData.reservationPolicy}
          </Descriptions.Item>
          <Descriptions.Item label="Transporte público cercano">
            {formData.publicTransport}
          </Descriptions.Item>
          <Descriptions.Item label="Parqueadero">
            {formData.parking}
          </Descriptions.Item>
        </>
      );
    }

    if (businessModel === "restaurant") {
      const displayedCuisine =
        formData.cuisine === "other"
          ? formData.otherCuisine
          : cuisineOptions.find((option) => option.value === formData.cuisine)
              ?.label;

      return (
        <>
          <Descriptions.Item label="Tipo de cocina">
            {displayedCuisine || "No especificado"}
          </Descriptions.Item>
          <Descriptions.Item label="Días de apertura">
            {formData.openDays.length} día(s): {formData.openDays.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Horario">
            {formData.openTime} - {formData.closeTime}
          </Descriptions.Item>
          {formData.specialHours && (
            <Descriptions.Item label="Horario Especial">
              {formData.specialOpenTime} - {formData.specialCloseTime}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Categorías de platos">
            {formData.categories.length} categoría(s):{" "}
            {formData.categories.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Capacidad máxima">
            {formData.capacity} personas
          </Descriptions.Item>
          <Descriptions.Item label="Número de mesas">
            {formData.tables}
          </Descriptions.Item>
          <Descriptions.Item label="Rango de precios">
            {formatPriceRange(formData.minPrice, formData.maxPrice)}
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de servicios adicionales">
            {formData.additionalServices.length} servicio(s):{" "}
            {formData.additionalServices
              .map(
                (service: string) =>
                  serviceOptions.find((option) => option.value === service)
                    ?.label
              )
              .join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Política de cancelación">
            {formData.cancellationPolicy}
          </Descriptions.Item>
          <Descriptions.Item label="Ambiente">
            {atmosphereOptions.find(
              (option) => option.value === formData.atmosphere
            )?.label || "No especificado"}
          </Descriptions.Item>
        </>
      );
    }
  };

  return (
    <Space>
      <Card>
        <h3
          className="text-lg md:text-xl font-semibold"
          style={{ marginBottom: "24px" }}
        >
          Revisión final de la información
        </h3>
        <Descriptions
          bordered
          column={1}
          size="small"
          layout={screens.xs ? "vertical" : "horizontal"}
        >
          <Descriptions.Item label="Nombre del establecimiento">
            {formData.name_establishment}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo de negocio">
            {capitalizeFirstLetter(formData.type, businessModel)}
          </Descriptions.Item>
          <Descriptions.Item label="Dirección">
            {formData.address}
          </Descriptions.Item>
          <Descriptions.Item label="Ciudad">
            {formData.city}
          </Descriptions.Item>
          <Descriptions.Item label="Teléfono">
            {formData.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Correo electrónico">
            {formData.email}
          </Descriptions.Item>
          <Descriptions.Item label="Descripción">
            {formData.description}
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de imágenes">
            {formData.photos ? formData.photos.length : 0} imagen(es)
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de preguntas frecuentes">
            {formData.faqs.length} pregunta(s)
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad de frases comunes">
            {formData.commonPhrases.length} frase(s):{" "}
            {formData.commonPhrases.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Opciones de pago">
            {formData.paymentOptions
              .map(
                (option: string) =>
                  paymentMethodOptions.find((method) => method.value === option)
                    ?.label
              )
              .join(", ")}
          </Descriptions.Item>
          {renderBusinessSpecificFields()}
        </Descriptions>
        <Divider />
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={prevStep} size="large">
            Regresar
          </Button>
          <Button type="primary" onClick={handleSubmit} size="large">
            Finalizar registro
          </Button>
        </div>
      </Card>
    </Space>
  );
}