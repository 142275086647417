import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { get } from "../../Api/http/httpClient";
import Lottie from "react-lottie";
import invoiceErrorAnimation from "../../assets/animations/invoice.json";
import { Spin, Button, Modal } from "antd";
import "./Invoice.css";

interface PaymentData {
  amount: number;
  status: string;
  payment_method_type: string;
  email: string;
  full_name: string;
  phone_number: string;
  family_name: string;
  plan_title: string;
  plan_messages: string;
  payment_reference: string;
  reference: string;
  creation_date: string;
  finalized_at: string;
}

interface InvoiceProps {
  user_data: any;
}

const Invoice: React.FC<InvoiceProps> = ({ user_data }) => {
  const navigate = useNavigate();
  const { establishment_id, reference } = useParams<{
    establishment_id: string;
    reference: string;
  }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false); // Inicialmente oculto

  const errorOptions = {
    loop: false,
    autoplay: true,
    animationData: invoiceErrorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Verifica el estado de integración cuando user_data esté disponible
  useEffect(() => {
    if (user_data && user_data["custom:wppConfigured"] === "false") {
      setShowInfoModal(true);
    }
  }, [user_data]);

  useEffect(() => {
    if (!establishment_id || !id || !reference) {
      setError(true);
      setLoading(false);
      return;
    }

    const fetchPaymentData = async () => {
      try {
        const data = await get(
          `/payment?establishmentID=${establishment_id}&payment_reference=${id}&reference=${reference}&fetchInvoice=${true}`
        );
        if (!data.answer) {
          setError(true);
        } else {
          setPaymentData(data.item);
        }
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };

    fetchPaymentData();
  }, [establishment_id, id, reference]);

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  const handleStartWppIntegration = () => {
    navigate("/dashboard/whatsapp-integration");
  };

  const handleModalClose = () => {
    setShowInfoModal(false);
  };

  if (loading) {
    return (
      <div className="centered-container">
        <Spin size="large" />
      </div>
    );
  }

  if (error || !paymentData) {
    return (
      <div className="centered-container">
        <Lottie options={errorOptions} height={400} width={400} />
      </div>
    );
  }

  const paymentStatusClass = `status-text ${paymentData?.status.toLowerCase()}`;

  return (
    <div className="invoice-page">
      {/* Modal informativo */}
      <Modal
        title="Paso Siguiente"
        open={showInfoModal}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Cerrar
          </Button>,
          <Button
            key="integrate"
            type="primary"
            onClick={handleStartWppIntegration}
          >
            Iniciar Integración
          </Button>,
        ]}
      >
        <p>
          Para empezar a usar el agente de WhatsApp, debe completar la integración
          con su número de WhatsApp. Esto habilitará todas las funciones de
          comunicación en tiempo real con sus clientes.
        </p>
      </Modal>

      <header className="header card">
        <div className="logo-container">
          <img
            src="https://docs.amplify.aws/assets/logo-dark.svg"
            alt="Logo"
            className="logo"
          />
          <span className="invoice-text">Factura</span>
        </div>
        <div className="header-buttons">
          <Button
            type="primary"
            onClick={handleGoToDashboard}
            style={{ marginRight: "8px" }}
          >
            Ir al Dashboard
          </Button>
          {user_data?.["custom:wppConfigured"] === "false" && (
            <Button type="default" onClick={handleStartWppIntegration}>
              Iniciar Integración con WhatsApp
            </Button>
          )}
        </div>
      </header>

      <div className="invoice-container">
        <div className="invoice-order-summary">
          <h2 className="section-title">Detalles del Pago</h2>
          <div className="plan-summary-box">
            <div className="plan-summary-header">
              <span className="plan-title">Monto Total</span>
              <span className="price-discount">
                {(paymentData?.amount).toLocaleString("es-CO")}{" "}
                COP
              </span>
            </div>
            <div className="divider"></div>
            <div className="plan-summary-details">
              <p>
                <strong>Estado del pago: </strong>
                <span className={paymentStatusClass}>
                  {translateStatus(paymentData?.status)}
                </span>
              </p>
              <p>
                <strong>Método de pago:</strong>{" "}
                {translatePaymentMethod(paymentData?.payment_method_type)}
              </p>
              <p>
                <strong>Correo electrónico:</strong> {paymentData?.email}
              </p>
              <p>
                <strong>Nombre completo:</strong> {paymentData?.full_name}
              </p>
              <p>
                <strong>Teléfono:</strong> {paymentData?.phone_number}
              </p>
              <p>
                <strong>Establecimiento:</strong> {paymentData?.family_name}
              </p>
              <p>
                <strong>Nombre del plan:</strong> {paymentData?.plan_title}
              </p>
              <p>
                <strong>Cantidad de mensajes:</strong>{" "}
                {paymentData?.plan_messages}
              </p>
              <div className="divider"></div>
              <p>
                <strong>Referencia del Pago:</strong>{" "}
                {paymentData?.payment_reference}
              </p>
              <p>
                <strong>Referencia Interna:</strong> {paymentData?.reference}
              </p>
              <p>
                <strong>Fecha de la Transacción:</strong>{" "}
                {formatDate(paymentData?.creation_date)}
              </p>
              <p>
                <strong>Fecha de Finalización:</strong>{" "}
                {formatDate(paymentData?.finalized_at)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;

// Funciones auxiliares
const translateStatus = (status: string | undefined): string => {
  switch (status) {
    case "APPROVED":
      return "Aprobado";
    case "DECLINED":
      return "Rechazado";
    case "PENDING":
      return "Pendiente";
    case "VOIDED":
      return "Anulado";
    case "ERROR":
      return "Error en el pago";
    default:
      return status || "";
  }
};

const translatePaymentMethod = (method: string | undefined): string => {
  if (method === "CARD") {
    return "Tarjeta de crédito/débito";
  } else if (method === "PSE") {
    return "PSE";
  }
  return method || "";
};

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";

  const date = new Date(dateString);

  const formattedDate = date.toLocaleString("es-CO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return formattedDate.replace(" a. m.", " a.m.").replace(" p. m.", " p.m.");
};