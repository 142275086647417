// App.tsx
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ConfigProvider, theme, Spin } from "antd";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import {
  getCurrentUser,
  fetchUserAttributes,
  signOut,
} from "@aws-amplify/auth";

// Importación directa de componentes
import Onboarding from "./pages/Onboarding/Onboarding";
import Dashboard from "./pages/Dashboard/Dashboard";
import Plans from "./pages/Plans/Plans";
import Checkout from "./pages/Checkout/Checkout";
import Invoice from "./pages/Invoice/Invoice";
import Login from "./pages/Login/Login";
import LandingPage from "./pages/LandingPage/LandingPage";

Amplify.configure(awsExports);

interface UserAttributes {
  email: string;
  email_verified: string;
  phone_number: string;
  phone_number_verified: string;
  family_name: string;
  address: string;
  "custom:establishment": string;
  "custom:establishment_id": string;
  "custom:plan": string;
  "custom:group_user": string;
  "custom:wppConfigured": string;
  sub: string;
}

const AppContent: React.FC = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [userData, setUserData] = useState<UserAttributes | null>(null);

  // Función para verificar la autenticación del usuario
  const checkAuthentication = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
      await fetchAttributes();
    } catch (error) {
      setIsAuthenticated(false);
      setUserData(null); // Limpiar userData si no está autenticado
    }
  };

  // Función para obtener los atributos del usuario
  const fetchAttributes = async () => {
    try {
      const attributesObject = await fetchUserAttributes();
      const userAttributes: UserAttributes = {
        email: attributesObject.email || "",
        email_verified: attributesObject.email_verified || "false",
        phone_number: attributesObject.phone_number || "",
        phone_number_verified:
          attributesObject.phone_number_verified || "false",
        family_name: attributesObject.family_name || "",
        address: attributesObject.address || "",
        "custom:establishment": attributesObject["custom:establishment"] || "",
        "custom:establishment_id":
          attributesObject["custom:establishment_id"] || "",
        "custom:plan": attributesObject["custom:plan"] || "",
        "custom:group_user": attributesObject["custom:group_user"] || "",
        "custom:wppConfigured":
          attributesObject["custom:wppConfigured"] || "false",
        sub: attributesObject.sub || "",
      };
      setUserData(userAttributes);
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  // useEffect que se ejecuta cada vez que cambia la ruta
  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Mostrar un spinner mientras se verifica la autenticación
  if (isAuthenticated === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Routes>
      {/* Ruta pública: siempre muestra la LandingPage */}
      <Route
        path="/"
        element={
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <LandingPage />
          </ConfigProvider>
        }
      />
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        path="/plans"
        element={
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <Plans />
          </ConfigProvider>
        }
      />
      <Route
        path="/checkout"
        element={
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <Checkout />
          </ConfigProvider>
        }
      />
      <Route
        path="/invoice/:establishment_id/:reference"
        element={
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            {userData ? (
              <Invoice user_data={userData} />
            ) : (
              <Spin size="large" />
            )}
          </ConfigProvider>
        }
      />
      <Route
        path="/enterprise-form"
        element={
          <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <Onboarding />
          </ConfigProvider>
        }
      />
      <Route
        path="/onboarding"
        element={
          <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
            <Onboarding />
          </ConfigProvider>
        }
      />
      {/* Ruta protegida: solo accesible si el usuario está autenticado */}
      <Route
        path="/dashboard/*"
        element={
          isAuthenticated ? (
            <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
              <Dashboard signOut={signOut} user_data={userData} />
            </ConfigProvider>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
