import React, { useState } from "react";
import { Modal, Button, Input, Spin, message, Select } from "antd";

const { Option } = Select;
const { TextArea } = Input;

interface FoodModalProps {
  open: boolean;
  onClose: () => void;
  action: { type: string; data?: any } | null;
  formData: { 
    name: string; 
    price: string; 
    category: string; 
    description: string 
  };
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleUpdate: () => Promise<boolean>;
  handleSave: () => Promise<boolean>;
  categories: string[];
}

const FoodModal: React.FC<FoodModalProps> = ({
  open,
  onClose,
  action,
  formData,
  handleChange,
  handleUpdate,
  handleSave,
  categories,
}) => {
  const [loading, setLoading] = useState(false);

  const validateNumber = (value: string) => {
    return /^\d*\.?\d*$/.test(value);
  };

  const handleActionClick = async () => {
    if (!formData.name || !formData.price || !formData.category || !formData.description) {
      message.error("Todos los campos son obligatorios");
      return;
    }

    setLoading(true);
    if (action?.type === "edit") {
      await handleUpdate();
    } else {
      await handleSave();
    }
    setLoading(false);
  };

  return (
    <Modal
      title={action?.type === "edit" ? "Editar Producto" : "Crear Producto"}
      open={open}
      maskClosable={false}
      onCancel={() => !loading && onClose()}
      footer={[
        <Button
          key="back"
          onClick={() => !loading && onClose()}
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleActionClick}
          loading={loading}
        >
          {action?.type === "edit" ? "Actualizar" : "Guardar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Input
          id="name"
          placeholder="Nombre del producto"
          value={formData?.name}
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        />
        <Input
          id="price"
          placeholder="Precio"
          value={formData?.price}
          onChange={(e) => {
            const { value } = e.target;
            if (validateNumber(value)) {
              handleChange(e);
            }
          }}
          style={{ marginBottom: 10 }}
        />
        <Select
          id="category"
          showSearch
          optionFilterProp="children"
          placeholder="Categoría"
          value={formData?.category || undefined}
          onChange={(value) =>
            handleChange({ target: { id: "category", value } } as any)
          }
          style={{ marginBottom: 10, width: "100%" }}
        >
          {categories.map((category) => (
            <Option key={category} value={category}>
              {category}
            </Option>
          ))}
        </Select>
        <TextArea
          id="description"
          placeholder="Descripción"
          value={formData?.description}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLTextAreaElement>)}
          style={{ marginBottom: 10 }}
          rows={4}
        />
      </Spin>
    </Modal>
  );
};

export default FoodModal;