import React, { useState, useEffect } from "react";
import { Card, Row, Col, message, Modal } from "antd";
import TableComponent from "../../../components/table/table";
import { get, post, put, del } from "../../../Api/http/httpClient";
import PlansModal from "../../../components/modals/PlansModal";

const { confirm } = Modal;

interface PlansDataProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const PlansData: React.FC<PlansDataProps> = ({ user_data, setTitleSection }) => {
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("plan_name");
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);
  const [action, setAction] = useState<any | null>(null);
  const [formData, setFormData] = useState({
    plan_name: "",
    plan_price: "",
    plan_description: "",
    plan_features: "[]",
    plan_message_count: "",
    isCustom: false,
  });

  useEffect(() => {
    setTitleSection("Planes");
  }, [setTitleSection]);

  const headers_table = {
    plan_id: "ID del Plan",
    plan_name: "Nombre del Plan",
    plan_price: "Precio",
    plan_description: "Descripción",
    plan_features_display: "Características",
    is_custom_display: "Plan Personalizado",
    plan_message_count: "Cantidad de Mensajes",
  };

  const getPlansData = async () => {
    setLoading(true);
    setDeleting(null);
    try {
      const res = await get(
        `plans?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&page=${page}&page_size=${itemsPerPage}&search=${search}&sort_by=${sortColumn}&order_by=${sortOrder}`
      );

      if (res.answer && res.items) {
        const formattedPlanItems = res.items.map((plan: any) => ({
          plan_id: plan.plan_id || null,
          plan_name: plan.plan_name,
          plan_price: plan.plan_price,
          plan_description: plan.plan_description,
          plan_features: plan.plan_features,
          plan_features_display: (
            <ul style={{ paddingLeft: "20px", margin: 0 }}>
              {plan.plan_features.map((feature: string, index: number) => (
                <li key={index} style={{ marginBottom: "4px" }}>{feature}</li>
              ))}
            </ul>
          ),
          plan_message_count: plan.plan_message_count,
          is_custom_display: plan.isCustom ? "Sí" : "No",
          isCustom: plan.isCustom
        }));
        setData(formattedPlanItems);

        const totalItems = res.total_items;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      } else {
        message.error("No se encontraron planes.");
      }
    } catch (error) {
      message.error("Error al obtener los datos de planes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlansData();
  }, [page, itemsPerPage, search, sortOrder, sortColumn]);

  const setActionHandler = (action: { type: string; data?: any }) => {
    if (action.type === "edit") {
      setSelectedPlan(action.data);
      setFormData({
        plan_name: action.data.plan_name,
        plan_price: action.data.plan_price,
        plan_description: action.data.plan_description || "",
        plan_features: JSON.stringify(action.data.plan_features || []),
        plan_message_count: action.data.plan_message_count,
        isCustom: action.data.isCustom,
      });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "delete") {
      setAction(action);
      showDeleteConfirm(action.data);
    } else if (action.type === "create") {
      setFormData({ plan_name: "", plan_price: "", plan_description: "", plan_features: "[]", plan_message_count: "", isCustom: false });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "reload") {
      getPlansData();
    }
  };

  const showDeleteConfirm = (plan: any) => {
    confirm({
      title: "¿Estás seguro de eliminar este plan?",
      content: `Plan: ${plan.plan_name}`,
      okText: "Sí, eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleDelete(plan);
      },
      onCancel() {
        setDeleting(null);
      },
    });
  };

  const handleDelete = async (plan: any) => {
    setDeleting(plan.plan_id);

    try {
      const res = await del("plans", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        plan_id: plan.plan_id,
        plan_name: plan.plan_name
      });

      if (res && res.answer) {
        message.success("Plan eliminado correctamente.");
        getPlansData();
      } else {
        message.error("Error al eliminar el plan.");
      }
    } catch (error) {
      message.error("Error al eliminar el plan.");
    } finally {
      setDeleting(null);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPlan(null);
    setAction(null);
  };

  const handleSave = async () => {
    setModalLoading(true);
    try {
      const formattedFormData = {
        ...formData,
        plan_features: JSON.parse(formData.plan_features || "[]"),
      };
      const res = await post("plans", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        ...formattedFormData,
      });

      if (res && res.answer) {
        message.success("Plan creado correctamente.");
        handleCloseModal();
        getPlansData();
      } else {
        message.error("Error al crear el plan.");
      }
    } catch (error) {
      message.error("Error al crear el plan.");
    } finally {
      setModalLoading(false);
    }
  };

  const handleUpdate = async () => {
    setModalLoading(true);
    try {
      if (selectedPlan) {
        const formattedFormData = {
          ...formData,
          plan_features: JSON.parse(formData.plan_features || "[]"),
        };
        const res = await put("plans", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          plan_id: selectedPlan.plan_id,
          ...formattedFormData,
        });

        if (res && res.answer) {
          const updatedData = data.map((item) =>
            item.plan_id === selectedPlan.plan_id ? { ...item, ...formattedFormData } : item
          );
          setData(updatedData);
          message.success("Plan actualizado correctamente.");
          handleCloseModal();
          getPlansData();
        } else {
          message.error("Error al actualizar el plan.");
        }
      } else {
        message.error("No se seleccionó ningún plan para actualizar.");
      }
    } catch (error) {
      message.error("Error al actualizar el plan.");
    } finally {
      setModalLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={23} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Card bordered={false} style={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: "0px" }}>
          <TableComponent
            data={data}
            headers_table={headers_table}
            loading={loading}
            setSearch={setSearch}
            setAction={setActionHandler}
            totalPages={totalPages}
            setPage={setPage}
            page={page}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            setSortOrder={setSortOrder}
            setSortColumn={setSortColumn}
            deleting={deleting}
          />
        </Card>
      </Col>

      <PlansModal
        open={modalOpen}
        onClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        action={action}
        loading={modalLoading}
      />
    </Row>
  );
};

export default PlansData;