import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { getLineChartConfig } from "./configs/LChart";

interface DataProp {
  data: any;
}

const LineChart: React.FC<DataProp> = ({ data }) => {
  const { Title, Paragraph } = Typography;

  const lineConfig = getLineChartConfig(data);

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Usuarios Activos</Title>
          <Paragraph className="lastweek">
            La semana pasada <span className="bnb2">{data?.weekly_comparison?.diff_percentage ?? 0}%</span>
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Clientes Atendidos</li>
            <li>{<MinusOutlined />} Usuarios</li>
            
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineConfig.options}
        series={lineConfig.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;