import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaFileAlt,
  FaImages,
  FaHome,
  FaComments,
  FaDatabase,
  FaUsers,
  FaWhatsapp,
  FaCreditCard,
  FaClipboardList,
  FaBox,
  FaChartBar,
  FaWarehouse,
  FaBoxOpen,
  FaTags,
} from "react-icons/fa";
import { MdMiscellaneousServices, MdPolicy } from "react-icons/md";
import { Layout, Menu, Spin } from "antd";
import logo from "../../assets/img/dashboard/logo_BN.png";
import "./Sidebar.css";

const { Sider } = Layout;

interface CustomSidenavProps {
  signOut: () => void;
  user_data: any;
}

const CustomSidenav: React.FC<CustomSidenavProps> = ({ user_data }) => {
  console.log(user_data);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (user_data) {
      setLoadingUserData(false);
    }
  }, [user_data]);

  const isAdmin = user_data?.["custom:group_user"] === "Administrador";
  const isEcommerce = user_data?.["custom:establishment"] === "e-commerce";

  const modules = [
    {
      key: "sub1",
      label: "General",
      icon: <FaHome />,
      children: [
        {
          key: "1",
          label: <Link to="/dashboard/general-data">Datos</Link>,
          icon: <FaFileAlt />,
        },
        // Mostrar el servicio solo si no es e-commerce
        ...(!isEcommerce
          ? [
              {
                key: "2",
                label: <Link to="/dashboard/general-services">Servicios</Link>,
                icon: <MdMiscellaneousServices />,
              },
            ]
          : []),
        {
          key: "3",
          label: <Link to="/dashboard/schedule">Horarios</Link>,
          icon: <FaClock />,
        },
        {
          key: "4",
          label: <Link to="/dashboard/policy-data">Políticas</Link>,
          icon: <MdPolicy />,
        },
        {
          key: "5",
          label: <Link to="/dashboard/multimedia">Multimedia</Link>,
          icon: <FaImages />,
        },
        {
          key: "sub2",
          label: "Inventario",
          icon: <FaWarehouse />,
          children: [
            {
              key: "6",
              label: <Link to="/dashboard/menu-categories">Categorías</Link>,
              icon: <FaTags />,
            },
            {
              key: "7",
              label: <Link to="/dashboard/products">Productos</Link>,
              icon: <FaBoxOpen />,
            },
          ],
        },
        {
          key: "8",
          label: <Link to="/dashboard/faqs">FAQs</Link>,
          icon: <FaFileAlt />,
        },
      ],
    },
    {
      key: "9",
      label: <Link to="/dashboard/payment-data">Pagos</Link>,
      icon: <FaCreditCard />,
    },
    ...(isAdmin
      ? [
          {
            key: "10",
            label: <Link to="/dashboard/plans-data">Planes</Link>,
            icon: <FaClipboardList />,
          },
          {
            key: "11",
            label: <Link to="/dashboard/packages-data">Paquetes</Link>,
            icon: <FaBox />,
          },
        ]
      : []),
    {
      key: "sub3",
      label: "Chats",
      icon: <FaComments />,
      children: [
        {
          key: "12",
          label: <Link to="/dashboard/normal-chat">Chat Interno</Link>,
          icon: <FaUsers />,
        },
        {
          key: "13",
          label: <Link to="/dashboard/whatsapp-chat">Gestionar WhatsApp</Link>,
          icon: <FaWhatsapp />,
        },
      ],
    },
    {
      key: "14",
      label: <Link to="/dashboard/chat-data">Historial de Chats</Link>,
      icon: <FaDatabase />,
    },
    {
      key: "15",
      label: <Link to="/dashboard/analytics">Analítica</Link>,
      icon: <FaChartBar />,
    },
  ];

  if (loadingUserData) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Spin size="large" style={{ color: "#00152D" }} />
      </div>
    );
  }

  if (!user_data) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px", color: "red" }}>
        Error: Datos de usuario no disponibles.
      </div>
    );
  }

  const siderStyle: React.CSSProperties = {
    overflow: "auto",
    height: "100vh",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: "thin",
    paddingTop: 20,
  };

  return (
    <Sider
      style={siderStyle}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={250}
    >
      <Link to={"/dashboard"} style={{ width: "100%" }}>
        <img
          style={{ width: "100%" }}
          src={logo}
          alt="Logo horizontal de Blox"
        />
      </Link>
      <Menu theme="dark" mode="inline" items={modules} style={{ marginTop: 20, paddingBottom: 50 }} />
    </Sider>
  );
};

export default CustomSidenav;