import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Spin,
  message,
  Typography,
  Checkbox,
} from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faCreditCard,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { get, put } from "../../../Api/http/httpClient";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  ButtonGroup,
  CheckboxButton,
  CheckboxContainer,
} from "../../../components/landingpage/styledComponents/Buttons";

const { Text } = Typography;

const paymentMethodOptions = [
  { label: "Efectivo", value: "cash", icon: faMoneyBill },
  { label: "Tarjeta de crédito", value: "credit-card", icon: faCreditCard },
  { label: "Tarjeta de débito", value: "debit-card", icon: faCreditCard },
  { label: "Pago móvil", value: "mobile-payment", icon: faMobileAlt },
];

const PolicyData: React.FC<{
  user_data: any;
  setTitleSection: (title: string) => void;
}> = ({ user_data, setTitleSection }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const screens = useBreakpoint();

  useEffect(() => {
    setTitleSection("Políticas del Establecimiento");
  }, [setTitleSection]);

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
        form.setFieldsValue(fetchedData);
        setOriginalValues(fetchedData);
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some((key) => {
      return originalValues[key] !== allValues[key];
    });
    setHasChanges(isDifferent);
  };

  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
    } else {
      try {
        setUpdating(true);
        await put("restaurant-onboarding", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          ...changes,
        });
        message.success("Datos actualizados correctamente.");
        fetchEstablishmentData();
        setHasChanges(false);
      } catch (error) {
        message.error("Error al actualizar los datos del establecimiento.");
      } finally {
        setUpdating(false);
      }
    }
  };

  // Verificar si es medical-office o legal-office para cambiar los labels
  const isMedicalOrLegal =
    user_data["custom:establishment"] === "medical-office" ||
    user_data["custom:establishment"] === "legal-office";

  const reservationPolicyLabel = isMedicalOrLegal
    ? "Política de agendamiento de citas"
    : "Política de Reservas";

  const cancellationPolicyLabel = isMedicalOrLegal
    ? "Política de cancelación de citas"
    : "Política de Cancelación";

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card
          title="Políticas del Establecimiento"
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: screens.xs ? "10px" : "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>
                Cargando datos del establecimiento...
              </Text>
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", padding: "40px" }}>
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text
                type="danger"
                style={{ display: "block", fontSize: "18px", marginTop: "20px" }}
              >
                Ocurrió un error al cargar los datos del establecimiento.
              </Text>
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "20px", borderRadius: "8px" }}
                onClick={fetchEstablishmentData}
              >
                Reintentar
              </Button>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              style={{ fontFamily: "Roboto, sans-serif" }}
            >
              {user_data["custom:establishment"] === "e-commerce" ? (
                <>
                  {/* Para establecimientos e-commerce */}
                  <Form.Item
                    label="Políticas de devolución y garantías"
                    name="returnPolicies"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingrese las políticas de devolución y garantías",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Ingrese las políticas de devolución y garantías"
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Descuentos y promociones"
                    name="discounts"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingrese los descuentos y promociones disponibles",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Ingrese los descuentos y promociones disponibles"
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  {/* Para establecimientos que NO son e-commerce */}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label={reservationPolicyLabel}
                        name="reservationPolicy"
                        rules={[
                          {
                            required: true,
                            message:
                              isMedicalOrLegal
                                ? "Por favor ingrese la política de agendamiento de citas"
                                : "Por favor ingrese la política de reservas",
                          },
                        ]}
                      >
                        <Input.TextArea
                          size="large"
                          placeholder={
                            isMedicalOrLegal
                              ? "Ingrese la política de agendamiento de citas"
                              : "Ingrese la política de reservas"
                          }
                          autoSize={{ minRows: 3, maxRows: 8 }}
                          style={{ borderRadius: "8px" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label={cancellationPolicyLabel}
                        name="cancellationPolicy"
                        rules={[
                          {
                            required: true,
                            message:
                              isMedicalOrLegal
                                ? "Por favor ingrese la política de cancelación de citas"
                                : "Por favor ingrese la política de cancelación",
                          },
                        ]}
                      >
                        <Input.TextArea
                          size="large"
                          placeholder={
                            isMedicalOrLegal
                              ? "Ingrese la política de cancelación de citas"
                              : "Ingrese la política de cancelación"
                          }
                          autoSize={{ minRows: 3, maxRows: 8 }}
                          style={{ borderRadius: "8px" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {/* Bloque de Opciones de Pago (se muestra para todos los modelos) */}
              <Form.Item
                label="Opciones de pago aceptadas"
                name="paymentOptions"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione al menos una opción de pago",
                  },
                ]}
              >
                <Checkbox.Group>
                  <ButtonGroup $justify="flex-start">
                    {paymentMethodOptions.map((payment) => (
                      <CheckboxContainer key={payment.value}>
                        <Checkbox value={payment.value} />
                        <CheckboxButton>
                          <label>
                            <FontAwesomeIcon
                              icon={payment.icon}
                              style={{ marginRight: 8 }}
                            />
                            <span>{payment.label}</span>
                          </label>
                        </CheckboxButton>
                      </CheckboxContainer>
                    ))}
                  </ButtonGroup>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={updating}
                  disabled={!hasChanges}
                  style={{
                    borderRadius: "8px",
                    background: hasChanges
                      ? "linear-gradient(45deg, #1890ff, #1d39c4)"
                      : "gray",
                    border: "none",
                  }}
                >
                  Guardar Cambios
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default PolicyData;