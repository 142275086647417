import React, { useState, useEffect } from "react";
import { Button, Skeleton, message, Tag } from "antd";
import { getCurrentUser } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import {
  ContainerBase,
  ContainerCenter,
  ContainerFooter,
  PricesList,
} from "../../components/landingpage/styledComponents/Containers";
import { Description, SubTitle } from "../../components/landingpage/styledComponents/Typography";
import { CardPrice } from "../../components/landingpage/styledComponents/Cards";
import { ButtonFill, ButtonOutline } from "../../components/landingpage/styledComponents/Buttons";
import IconCheck from "../../assets/img/landingPage/check-circle.png";
import { get } from "../../Api/http/httpClient";
import logo from "../../assets/img/dashboard/logo horizontal_BN.webp";
import "./Plans.css";

interface Plan {
  title: string;
  description: string;
  messages: string;
  price: string | number;
  features: string[];
  code_plan: string;
  isCustom?: boolean;
}

const Plans: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));

    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await get("plans?fetch_all=true");
      if (response.answer) {
        const formattedPlans = response.items
          .map((plan: any) => ({
            title: plan.plan_name,
            description: plan.plan_description,
            messages: plan.plan_message_count,
            price:
              plan.isCustom === "true"
                ? "Plan cotizado"
                : parseFloat(plan.plan_price),
            features: plan.plan_features,
            code_plan: plan.plan_id,
            isCustom: plan.isCustom === "true",
          }))
          .sort((a: Plan, b: Plan) => {
            if (a.isCustom) return 1;
            if (b.isCustom) return -1;
            return typeof a.price === "number" && typeof b.price === "number"
              ? a.price - b.price
              : 0;
          });

        setPlans(formattedPlans);
      } else {
        message.error("No se pudieron cargar los planes.");
      }
    } catch (error) {
      message.error("Error al cargar los planes.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price: string | number) => {
    if (typeof price === "string") return price;
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handlePurchase = (plan: Plan) => {
    if (plan.isCustom) {
      navigate("/enterprise-form");
    } else {
      navigate("/checkout", { state: { plan } });
    }
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <header className="plans-header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <Button
          type="primary"
          className="dashboard-button"
          onClick={handleGoToDashboard}
        >
          Ir al Dashboard
        </Button>
      </header>

      <ContainerFooter style={{ marginTop: "20px" }}>
        <ContainerBase>
          <ContainerCenter>
            <SubTitle>Planes Flexibles para Cada Tipo de Negocio</SubTitle>
            <Description>
              Elige el plan que mejor se adapte a las necesidades de tu negocio.
              No importa si eres una pequeña tienda o una gran corporación,
              tenemos un plan para ti.
            </Description>
          </ContainerCenter>

          {isLoading ? (
            <PricesList>
              {Array.from({ length: 5 }).map((_, index) => (
                <CardPrice key={index} className="CardPrice">
                  <Skeleton active paragraph={{ rows: 4 }} />
                </CardPrice>
              ))}
            </PricesList>
          ) : (
            <PricesList
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              }}
            >
              {plans.map((plan, index) => (
                <CardPrice
                  key={index}
                  className={`CardPrice ${plan.isCustom ? "custom-plan" : ""}`}
                >
                  <h3 className="plan-title">{plan.title}</h3>
                  <p className="plan-description">{plan.description}</p>
                  <p className="price">
                    <span>{formatPrice(plan.price)}</span> / mes
                  </p>

                  {/* Tag con el degradado solicitado */}
                  <Tag
                    style={{
                      background: "linear-gradient(90.08deg, #0E6EAF -26.21%, #4296DE 37.83%, #4296DE 66.74%, #0E6EAF 116.78%)",
                      color: "#fff",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      padding: "5px 12px",
                      borderRadius: "16px",
                      border: "none",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                      marginBottom: "10px",
                    }}
                  >
                    {plan.messages} mensajes
                  </Tag>

                  <hr />
                  <ul>
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <img
                          src={IconCheck}
                          alt="Ícono de verificación"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>

                  {plan.isCustom ? (
                    <ButtonOutline onClick={() => handlePurchase(plan)}>
                      Contactarnos
                    </ButtonOutline>
                  ) : (
                    <ButtonFill onClick={() => handlePurchase(plan)}>
                      Comprar Ahora
                    </ButtonFill>
                  )}
                </CardPrice>
              ))}
            </PricesList>
          )}
        </ContainerBase>
      </ContainerFooter>
    </>
  );
};

export default Plans;