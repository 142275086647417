import React, { useState, useEffect } from "react";
import { Card, Row, Col, List, Button, message, Popconfirm, Pagination } from "antd";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import FaqModal from "../../../components/modals/FaqModal"; // Manteniendo el modal ya existente
import { get, post, put, del } from "../../../Api/http/httpClient";

interface FAQDataProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const FAQs: React.FC<FAQDataProps> = ({ user_data, setTitleSection }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState<any | null>(null);
  const [action, setAction] = useState<any | null>(null);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  // Paginación
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setTitleSection("Preguntas Frecuentes (FAQs)");
  }, [setTitleSection]);

  const getFAQData = async (
    page = 1,
    pageSize = 5,
    sortBy = "question",
    search = ""
  ) => {
    setLoading(true);
    try {
      const res = await get(
        `faqs?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&page=${page}&page_size=${pageSize}&sort_by=${sortBy}&search=${search}`
      );

      if (res.answer && res.items) {
        const formattedFAQs = res.items.map((faq: any, index: number) => ({
          id: index + 1 + (page - 1) * pageSize, // Ajuste del ID para que sea incremental en diferentes páginas
          question: faq.question,
          answer: faq.answer,
        }));
        setData(formattedFAQs);
        setTotalItems(res.total_items);
      } else {
        message.error("No se encontraron FAQs.");
      }
    } catch (error) {
      message.error("Error al obtener los datos de FAQs.");
    }
    setLoading(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFAQ(null);
    setAction(null);
  };

  const handleFaqSubmit = async () => {
    if (action?.type === "edit") {
      await handleUpdate();
    } else {
      await handleSave();
    }
  };

  const handleSave = async (): Promise<boolean> => {
    setLoading(true);
    try {
      const res = await post(`faqs`, {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        question: formData.question,
        answer: formData.answer,
      });

      if (res.answer) {
        const newFAQ = {
          id: data.length + 1,
          question: formData.question,
          answer: formData.answer,
        };
        setData([...data, newFAQ]);
        message.success("FAQ creada correctamente");
        handleCloseModal();
        return true;
      } else {
        message.error("Error al crear la FAQ.");
        return false;
      }
    } catch (error) {
      message.error("Error al crear la FAQ.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (): Promise<boolean> => {
    setLoading(true);
    try {
      const res = await put(`faqs`, {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        old_question: selectedFAQ.question,
        old_answer: selectedFAQ.answer,
        new_question: formData.question,
        new_answer: formData.answer,
      });

      if (res.answer) {
        const updatedData = data.map((item) =>
          item.id === selectedFAQ.id ? { ...item, ...formData } : item
        );
        setData(updatedData);
        message.success("FAQ actualizada correctamente");
        handleCloseModal();
        return true;
      } else {
        message.error("Error al actualizar la FAQ.");
        return false;
      }
    } catch (error) {
      message.error("Error al actualizar la FAQ.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (faq: any): Promise<boolean> => {
    setLoading(true);
    try {
      const res = await del(`faqs`, {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        question_to_delete: faq.question,
        answer_to_delete: faq.answer,
      });

      if (res.answer) {
        const updatedFaqs = data.filter((item) => item.id !== faq.id);
        setData(updatedFaqs);
        message.success("FAQ eliminada correctamente");
        return true;
      } else {
        message.error("Error al eliminar la FAQ.");
        return false;
      }
    } catch (error) {
      message.error("Error al eliminar la FAQ.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleFaqEdit = (index: number) => {
    const faq = data[index];
    setSelectedFAQ(faq);
    setFormData({
      question: faq.question,
      answer: faq.answer,
    });
    setAction({ type: "edit", data: faq });
    handleOpenModal();
  };

  const handleFaqDelete = async (index: number) => {
    const faqToDelete = data[index];
    await handleDelete(faqToDelete);
  };

  const handleCreateFaq = () => {
    setFormData({ question: "", answer: "" });
    setAction({ type: "create" });
    handleOpenModal();
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setPage(page);
    setPageSize(pageSize || 5);
    getFAQData(page, pageSize);
  };

  const handleRefresh = () => {
    getFAQData(page, pageSize); // Actualiza la lista de FAQs en la página actual
  };

  useEffect(() => {
    getFAQData();
  }, []);

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={22}>
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                Preguntas Frecuentes (FAQs)
              </span>
              <div>
                <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={handleRefresh}
                  style={{ marginRight: "10px" }}
                >
                  Actualizar
                </Button>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleCreateFaq}
                >
                  Añadir FAQ
                </Button>
              </div>
            </div>
          }
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px",
          }}
        >
          <List
            bordered
            dataSource={data}
            loading={loading}
            renderItem={(item: any, index: number) => (
              <List.Item
                actions={[
                  <Button type="link" onClick={() => handleFaqEdit(index)}>
                    Editar
                  </Button>,
                  <Popconfirm
                    title="¿Estás seguro de eliminar esta FAQ?"
                    onConfirm={() => handleFaqDelete(index)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <Button type="link" danger>
                      Eliminar
                    </Button>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={item.question}
                  description={item.answer}
                />
              </List.Item>
            )}
          />
          <Pagination
            current={page}
            total={totalItems}
            pageSize={pageSize}
            showSizeChanger
            onChange={handlePageChange}
            pageSizeOptions={["5", "10", "15"]}
            style={{ textAlign: "center", marginTop: "20px" }}
          />
        </Card>
      </Col>

      <FaqModal
        open={modalOpen}
        onClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        action={action}
      />
    </Row>
  );
};

export default FAQs;