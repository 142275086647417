// Un diccionario para mapear el nombre corto del mes a su índice (1-based para facilitar)
const MONTHS_MAP: Record<string, number> = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  
  /**
   * Parsea un string tipo "Dec 2024" o "Jan 2025" a un objeto:
   * { year: 2024, monthIndex: 12 } // Nota: monthIndex para comparar fácil
   */
  export function parseMonthYear(monthYearString: string) {
    // Divide el string, por ejemplo: "Dec 2024" => ["Dec", "2024"]
    const [monthString, yearString] = monthYearString.split(" ");
    // Normalmente monthString será "Dec", yearString será "2024"
    
    const year = parseInt(yearString, 10);
    const monthIndex = MONTHS_MAP[monthString] || 0;
  
    return { year, monthIndex };
  }
  
  /**
   * Ordena las llaves de year_monthly_interactions cronológicamente
   * de la más antigua a la más reciente.
   */
  export function sortByChronology(keys: string[]): string[] {
    return keys.sort((a, b) => {
      const { year: yearA, monthIndex: monthA } = parseMonthYear(a);
      const { year: yearB, monthIndex: monthB } = parseMonthYear(b);
  
      // Primero comparamos año
      if (yearA !== yearB) {
        return yearA - yearB;
      }
      // Si es el mismo año, comparamos mes
      return monthA - monthB;
    });
  }