import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  Avatar,
  Spin,
  List,
  message as antdMessage,
  Tooltip,
} from "antd";
import {
  SendOutlined,
  WhatsAppOutlined,
  ReloadOutlined,
  LoadingOutlined,
  CrownOutlined,
  UserOutlined,
  RobotOutlined,
  TeamOutlined,
  SearchOutlined,
  SmileOutlined,
  UnlockOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import webSocketService from "../../services/WebSocketService";
import { get, post, put } from "../../Api/http/httpClient";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";
import "./WhatsAppChat.css";

/* Interfaces y Funciones Auxiliares */

interface Message {
  id: string;
  text: string;
  timestamp: string; // ISO string
  role: "user" | "assistant" | "agent";
  loading?: boolean;
}

interface ChatItem {
  chat_uuid: string;
  name_user: string;
  is_controlled: boolean; // Indica si el chat está controlado por un agente
  history: Message[];
}

interface WhatsAppChatProps {
  user_data: {
    "custom:establishment_id": string;
    "custom:wppConfigured": string; // 'true' o 'false'
    "custom:plan": string;          // '0' o algún otro valor
    // Añade otros campos según sea necesario
  };
}

const generateUniqueId = (): string => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

const formatTimestamp = (timestamp: string): string => {
  return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
};

const WhatsAppChat: React.FC<WhatsAppChatProps> = ({ user_data }) => {
  /* Estados principales */
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [chatList, setChatList] = useState<ChatItem[]>([]);
  const [selectedChatUUID, setSelectedChatUUID] = useState<string | null>(null);
  const [loadingChats, setLoadingChats] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  
  // Nuevo estado para manejar la carga del botón de liberar chat
  const [isLiberating, setIsLiberating] = useState<boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  /* Función para enviar un mensaje a WhatsApp */
  const sendMessageToWhatsApp = async () => {
    if (!newMessage.trim()) return;
    setIsSending(true);

    try {
      const res = await post("/chat-ai", {
        establishment_id: user_data["custom:establishment_id"],
        name_establishment: "Restaurante Stellion",
        chat_uuid: selectedChatUUID,
        whatsapp_message: newMessage,
        whatsapp_send: true,
      });

      const answer = res.data?.answer ?? res.answer;

      if (answer) {
        const newMsg: Message = {
          id: generateUniqueId(),
          text: newMessage,
          timestamp: new Date().toISOString(),
          role: "agent",
        };
        setMessages((prevMessages) => [...prevMessages, newMsg]);
        setNewMessage("");
        antdMessage.success(
          res.data?.message || res.message || "Mensaje enviado correctamente."
        );
      } else {
        antdMessage.error("Error al enviar el mensaje a WhatsApp.");
      }
    } catch (error) {
      antdMessage.error("Error al conectar con la API.");
    } finally {
      setIsSending(false);
    }
  };

  /* Manejador para enviar el mensaje (por Enter o Button) */
  const handleSendMessage = () => {
    if (!isConnected || !selectedChatUUID) {
      antdMessage.warning(
        "Por favor, selecciona un chat antes de enviar un mensaje."
      );
      return;
    }
    if (newMessage.trim() !== "") {
      sendMessageToWhatsApp();
    }
  };

  /* Obtener la lista completa de chats */
  const getChatData = async () => {
    setLoadingChats(true);
    try {
      const res = await get(
        `/chat-ai?establishmentID=${user_data["custom:establishment_id"]}&return_all=true&channel_filter=whatsapp`
      );

      if (res.answer && res.items) {
        setChatList(
          res.items.map((chat: any) => ({
            chat_uuid: chat.chat_uuid,
            name_user: chat.name_user || "Usuario no identificado",
            is_controlled: chat.is_controlled,
            history: chat.history.map((msg: any) => ({
              id: generateUniqueId(),
              text: msg.message,
              timestamp: msg.timestamp,
              role: msg.role,
            })),
          }))
        );
      } else {
        antdMessage.error("No se encontraron chats.");
      }
    } catch (error) {
      antdMessage.error("Error al obtener los datos de chats.");
    } finally {
      setLoadingChats(false);
    }
  };

  /* Obtener el historial de un chat específico */
  const fetchChatHistory = async (chatUUID: string) => {
    setLoadingHistory(true);
    try {
      const res = await get(
        `/chat-ai?establishmentID=${user_data["custom:establishment_id"]}&chat_uuid=${chatUUID}`
      );

      if (res.answer && res.data) {
        const combinedMessages: Message[] = res.data.history
          .filter((item: any) => item.message !== "ß-Mensaje enviado por la plataforma-ß")
          .map((item: any) => ({
            id: generateUniqueId(),
            text: item.message,
            timestamp: item.timestamp,
            role: item.role as "user" | "assistant" | "agent",
          }));

        setMessages(combinedMessages);
      } else {
        antdMessage.error("No se encontraron mensajes para este chat.");
      }
    } catch (error) {
      antdMessage.error("Error al obtener el historial de chat.");
    } finally {
      setLoadingHistory(false);
    }
  };

  /* Conectar WebSocket a un chat (por su UUID) */
  const connectWebSocket = (chat_uuid: string) => {
    if (!chat_uuid) return;
    setIsConnecting(true);

    webSocketService.disconnect();

    webSocketService.connect(
      user_data,
      chat_uuid,
      "CHAT",
      null,
      () => {
        setIsConnected(true);
        setIsConnecting(false);
      },
      () => {
        setIsConnected(false);
        setIsConnecting(false);
      },
      () => {
        setIsConnected(false);
        setIsConnecting(false);
        antdMessage.error("WebSocket falló al conectar. Intenta reconectar.");
      }
    );
  };

  /* Al hacer clic en un chat de la lista */
  const handleChatSelect = (chat_uuid: string) => {
    setSelectedChatUUID(chat_uuid);
    fetchChatHistory(chat_uuid);
    connectWebSocket(chat_uuid);
  };

  /* Actualizar todos los chats (botón de refresh global) */
  const handleRefreshAllChats = async () => {
    setIsRefreshing(true);
    try {
      await getChatData();
      antdMessage.success("Historiales de chats actualizados correctamente.");
    } catch (error) {
      // Manejo de error ya implementado en getChatData
    } finally {
      setIsRefreshing(false);
    }
  };

  /* Refrescar historial del chat seleccionado */
  const handleRefreshSelectedChat = () => {
    if (selectedChatUUID) {
      fetchChatHistory(selectedChatUUID);
      antdMessage.success("Historial de chat recargado.");
    } else {
      antdMessage.warning("No hay un chat seleccionado para recargar.");
    }
  };

  /* Liberar el chat (si está controlado por un agente) */
  const handleLiberarChat = async () => {
    if (!selectedChatUUID) {
      antdMessage.warning("No hay un chat seleccionado para liberar.");
      return;
    }

    setIsLiberating(true); // Iniciar el estado de carga

    try {
      const res = await put("/chat-ai", {
        release_chat: true,
        establishment_id: user_data["custom:establishment_id"],
        chat_uuid: selectedChatUUID,
      });

      if (res.answer) {
        antdMessage.success("Chat liberado exitosamente.");
        setChatList((prevChats) =>
          prevChats.map((chat) =>
            chat.chat_uuid === selectedChatUUID
              ? { ...chat, is_controlled: false }
              : chat
          )
        );
      } else {
        antdMessage.error(res.message || "Error al liberar el chat.");
      }
    } catch (error) {
      antdMessage.error("Error al conectar con la API para liberar el chat.");
    } finally {
      setIsLiberating(false); // Finalizar el estado de carga
    }
  };

  /* useEffects para la configuración inicial */
  useEffect(() => {
    if (user_data["custom:wppConfigured"] === "false") {
      antdMessage.warning("Por favor, configura tu WhatsApp para comenzar.");
      window.location.href = "/dashboard/whatsapp-integration";
    } else if (user_data["custom:plan"] !== "0") {
      getChatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_data]);

  useEffect(() => {
    return () => {
      webSocketService.disconnect();
    };
  }, []);

  /* Scroll automático al final cada vez que cambian los mensajes */
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /* Formatear el contenido del mensaje (Markdown, imágenes, etc.) */
  const formatMessageContent = (text: string) => {
    const imageRegex = /\.(jpeg|jpg|gif|png)$/i;

    if (imageRegex.test(text)) {
      return (
        <img
          src={text}
          alt="Imagen enviada"
          style={{ maxWidth: "100%", borderRadius: "8px", marginTop: "10px" }}
        />
      );
    }

    return (
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ node, ...props }) => (
            <img
              src={props.src || ""}
              alt={props.alt || "Imagen enviada"}
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "5px" }} {...props} />
          ),
          p: ({ node, ...props }) => (
            <p style={{ margin: "5px 0" }} {...props} />
          ),
        }}
      />
    );
  };

  /* Recibir mensajes del WebSocket */
  const handleIncomingWebSocketMessage = (data: any) => {
    console.log("Mensaje recibido por WebSocket:", data);
    const validRoles = ["assistant", "agent", "user"];
    const role = validRoles.includes(data.role) ? data.role : "user";

    const newMessage: Message = {
      id: generateUniqueId(),
      text: data.message,
      timestamp: data.timestamp,
      role,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  useEffect(() => {
    webSocketService.setOnMessageCallback(handleIncomingWebSocketMessage);
  }, []);

  /* Filtrar la lista de chats con el buscador */
  const filteredChatList = chatList.filter(
    (chat) =>
      chat.name_user.toLowerCase().includes(searchQuery.toLowerCase()) ||
      chat.chat_uuid.toLowerCase().includes(searchQuery.toLowerCase())
  );

  /* Manejo de Emojis */
  const addEmoji = (emoji: any) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  /* Chat seleccionado para mostrar info en el encabezado */
  const selectedChat = chatList.find(
    (chat) => chat.chat_uuid === selectedChatUUID
  );

  return (
    <div className="whatsapp-chat-wrapper">
      {/* Sidebar Izquierdo: Lista de Chats */}
      <div className="whatsapp-chat-sidebar">
        <div className="whatsapp-chat-sidebar-header">
          <h3>Historial de Chats</h3>
          <Tooltip title="Actualizar todos los historiales">
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={handleRefreshAllChats}
              loading={isRefreshing}
              title="Actualizar todos los historiales"
            />
          </Tooltip>
        </div>

        {/* Buscador de chats */}
        <Input
          placeholder="Buscar chats"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          className="search-input"
          prefix={<SearchOutlined />}
        />

        {loadingChats ? (
          <div className="loading-container">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : filteredChatList.length === 0 ? (
          <div className="no-chats">No se encontraron chats.</div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={filteredChatList}
            renderItem={(chat: ChatItem) => (
              <List.Item
                className={`ant-list-item chat-item ${
                  selectedChatUUID === chat.chat_uuid ? "selected-chat" : ""
                }`}
                onClick={() => handleChatSelect(chat.chat_uuid)}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    <span
                      className="ant-list-item-meta-title"
                      style={{
                        fontWeight: "bold",
                        color:
                          selectedChatUUID === chat.chat_uuid
                            ? "#0056b3"
                            : "#333",
                      }}
                    >
                      {`Usuario: ${chat.name_user}`}
                      {/* Icono con Tooltip para indicar que está controlado por un agente */}
                      {chat.is_controlled && (
                        <Tooltip title="Este chat está siendo atendido por un agente">
                          <span className="agent-controlled-badge">
                            <CustomerServiceOutlined style={{ marginRight: 4 }} />
                          </span>
                        </Tooltip>
                      )}
                    </span>
                  }
                  description={
                    <span
                      style={{
                        color:
                          selectedChatUUID === chat.chat_uuid
                            ? "#0056b3"
                            : "#666",
                      }}
                    >
                      {`Chat UUID: ${chat.chat_uuid}`}
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </div>

      {/* Área Principal de Chat */}
      <div className="whatsapp-chat-container">
        {/* Header del chat */}
        <div className="whatsapp-chat-header">
          <Avatar size="large" icon={<WhatsAppOutlined />} className="mr-2" />
          <div className="chat-name">WhatsApp Bot</div>

          {user_data["custom:plan"] !== "0" && (
            <div className="connection-status-wrapper">
              <div
                className={`connection-status-circle ${
                  isConnecting
                    ? "connecting-circle"
                    : isConnected
                    ? "connected-circle"
                    : "disconnected-circle"
                }`}
              ></div>
              <div className="connection-status-text">
                {isConnecting
                  ? "Conectando..."
                  : isConnected
                  ? "Conectado"
                  : "Desconectado"}
              </div>
            </div>
          )}

          {isConnecting && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}

          {user_data["custom:plan"] === "0" ? (
            <Button
              type="primary"
              icon={<CrownOutlined />}
              onClick={() => (window.location.href = "/plans")}
              style={{ marginLeft: "10px" }}
            >
              Actualízate a Premium
            </Button>
          ) : (
            selectedChatUUID &&
            !isConnected && (
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => connectWebSocket(selectedChatUUID)}
                style={{ marginLeft: "10px" }}
                disabled={isConnecting}
              >
                Reconectar
              </Button>
            )
          )}

          {/* Botón para recargar solo el historial de este chat */}
          {selectedChatUUID && (
            <Tooltip title="Recargar historial del chat">
              <Button
                type="text"
                icon={<ReloadOutlined />}
                onClick={handleRefreshSelectedChat}
                style={{ marginLeft: "10px" }}
                className="header-icon-button"
              />
            </Tooltip>
          )}

          {/* Botón para liberar el chat si está controlado */}
          {selectedChat && selectedChat.is_controlled && (
            <Tooltip title="Liberar chat">
              <Button
                type="text"
                icon={<UnlockOutlined />}
                onClick={handleLiberarChat}
                style={{ marginLeft: "10px" }}
                className="header-icon-button"
                loading={isLiberating} // Agregar la propiedad loading
              />
            </Tooltip>
          )}
        </div>

        {/* Área donde se muestran los mensajes */}
        <div className="whatsapp-chat-messages">
          {loadingHistory ? (
            <div className="loading-container">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : !selectedChatUUID ? (
            <div className="no-chat-selected">
              Selecciona un chat para cargar la conversación.
            </div>
          ) : messages.length === 0 ? (
            <div className="no-messages">No hay mensajes en este chat.</div>
          ) : (
            messages.map((msg: Message) => (
              <div
                key={msg.id}
                className={`chat-message ${
                  msg.role === "assistant"
                    ? "received"
                    : msg.role === "agent"
                    ? "agent-received"
                    : "sent"
                }`}
              >
                {/* Avatar según el rol */}
                {msg.role === "user" && (
                  <Avatar
                    icon={<UserOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#52c41a" }}
                  />
                )}

                {msg.role === "assistant" && (
                  <Avatar
                    icon={<RobotOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#273c75" }}
                  />
                )}

                {msg.role === "agent" && (
                  <Avatar
                    icon={<TeamOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#44bd32" }}
                  />
                )}

                {/* Contenido de la burbuja */}
                <div className="chat-message-content">
                  {msg.loading ? <Spin /> : formatMessageContent(msg.text)}
                  <div className="chat-message-time">
                    {formatTimestamp(msg.timestamp)}
                  </div>
                </div>
              </div>
            ))
          )}
          {/* Ref para el scroll */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input para escribir mensajes */}
        <div className="whatsapp-chat-input">
          <div className="input-container">
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Escribe un mensaje"
              onPressEnter={handleSendMessage}
              disabled={isSending || !isConnected}
              className="message-input"
            />
            <Button
              type="text"
              icon={<SmileOutlined />}
              onClick={toggleEmojiPicker}
              className="emoji-button"
            />
            {showEmojiPicker && (
              <div className="emoji-picker">
                <Picker data={data} onEmojiSelect={addEmoji} theme="light" />
              </div>
            )}
          </div>
          <Button
            type="primary"
            shape="circle"
            icon={isSending ? <LoadingOutlined /> : <SendOutlined />}
            onClick={handleSendMessage}
            disabled={!isConnected || isSending}
            className="chat-send-button"
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppChat;