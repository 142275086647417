import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import { getEChartConfig } from "./configs/eChart";

interface DataProp {
  data: any;
}

const EChart: React.FC<DataProp> = ({ data }) => {
  const { Title, Paragraph } = Typography;

  const items = [
    {
      Title: data?.year_total_users ?? 0,
      user: "Usuarios",
    },
    // {
    //   Title: "2m",
    //   user: "Clicks",
    // },
    // {
    //   Title: "$772",
    //   user: "Ventas",
    // },
    // {
    //   Title: "82",
    //   user: "Artículos",
    // },
  ];

  const chartConfig = getEChartConfig(data);

  return (
    <>
      <div id="chart">
        <Title level={5}>Interacciones</Title>
        <ReactApexChart
          className="bar-chart"
          options={chartConfig.options}
          series={chartConfig.series}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Usuarios Activos</Title>
        <Paragraph className="lastweek">
          La semana pasada <span className="bnb2">{data?.weekly_comparison?.diff_percentage ?? 0}%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          Aquí tienes un resumen del rendimiento de tu chatbot en el último año:
        </Paragraph>
        <Row gutter={[16, 16]}>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;