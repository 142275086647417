import styled from 'styled-components';

export const ContainerLanding = styled.div`
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
`;

export const ContainerBase = styled.div.attrs<{ $height?: string; }>((props) => ({
    $height: props.$height,
}))`
    position: relative;
    max-width: 1154px;
    width: 100%;
    height: ${({ $height }) => $height || 'auto'};
    margin: 0 auto;
    z-index: 2;
    padding: 30px 50px;
    @media (max-width: 480px) {
        padding: 0 20px;
    }
`;

export const ContainerHero = styled.section`
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: rgba(14, 13, 13, 1);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    padding-top: 150px;
`;

export const ContainerFeatures = styled(ContainerHero).attrs<{ $background?: string }>((props) => ({
    $background: props.$background,
}))`
    padding: 150px 0;
    background: ${( props ) => props.$background ? props.$background : '#FAFAFA'};
    align-items: center;
    overflow: visible;
    @media (max-width: 1024px) {
        padding: 100px 0;
    }
    @media (max-width: 1024px) {
        overflow: hidden;
    }
`;

export const ContainerCTA = styled(ContainerFeatures)`
    min-height: auto;
    overflow-y: hidden;
    @media (max-width: 760px) {
        padding-bottom: 0;
    }
`;

export const ContainerDemo = styled(ContainerFeatures)`
    min-height: auto;
    &.sticky {
        position: sticky;
        top: 0;
        @media (max-width: 780px) {
            position: relative;
        }
    }
`;

export const ContainerFooter = styled(ContainerHero)`
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
`;

export const ContainerHeader = styled.header.attrs<{ $background?: string }>((props) => ({
    $background: props.$background,
}))`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ $background }) => $background || 'rgba(14, 13, 13, 1)'};
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 100px;
    z-index: 100;
    @media (max-width: 1024px) {
        padding: 0 50px;
    }
    @media (max-width: 480px) {
        padding: 0 20px;
    }
`;

export const ContainerMenu = styled.div.attrs<{ $isOpen: boolean }>((props) => ({
    $isOpen: props.$isOpen,
}))`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: ${( props ) => props.$isOpen ? '0' : '-100%'};
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(14, 13, 13, 1);
    transition: top 0.5s ease-in-out;
    z-index: 99;
`;

export const ContainerCenter = styled.div.attrs<{ 
    $isInline?: boolean; 
    $align?: string; 
    $justify?: string; 
    $width?: string; 
    $height?: string;
    $wrap?: boolean;
    $background?: string;
    $gap?: string;
    $padding?: string;
    $radius?: string;
}>((props) => ({
    $isInline: props.$isInline,
    $align: props.$align,
    $justify: props.$justify,
    $width: props.$width,
    $height: props.$height,
    $wrap: props.$wrap,
    $background: props.$background,
    $gap: props.$gap,
    $padding: props.$padding,
    $radius: props.$radius,
}))`
    width: ${({ $width }) => $width || '100%'};
    height: ${({ $height }) => $height || 'auto'};
    display: flex;
    flex-direction: ${({ $isInline }) => $isInline ? 'row' : 'column'};
    justify-content: ${({ $justify }) => $justify || 'center'};
    align-items: ${({ $align }) => $align || 'center'};
    flex-wrap: ${({ $wrap }) => $wrap ? 'wrap' : 'no-wrap'};
    gap: ${({ $gap }) => $gap || '16px'};
    border-radius: ${({ $radius }) => $radius || 0 };
    background: ${({ $background }) => $background || 'transparent'};
    padding: ${({$padding}) => $padding || 0 };
    overflow: auto hidden;
    scrollbar-width: none;

    &.containerCenterCTA {
        @media (max-width: 760px) {
            flex-direction: column-reverse;
            gap: 40px;
        }
    }

    &.containerCenterColumnRight {
        @media (max-width: 1024px) {
            width: 50%;
            h3 {
                font-size: 32px;
            }
            p {
                width: 100%;
            }
        }
        @media (max-width: 760px) {
            width: 100%;
            align-items: center;
            h3, p {
                text-align: center;
            }
        }
    }
`;

export const ContainerCenterProblem = styled(ContainerCenter)`
    align-items: flex-start;
    width: 50%;
    p {
        margin: 0;
    }
    @media (max-width: 1024px) {
        h3 {
            font-size: 32px;
        }
        p {
            width: 100%;
        }
    }
    @media (max-width: 780px) {
        width: 100%;
    }
`;

export const ContainerCenterSolution = styled(ContainerCenter)`
    align-items: flex-start;
    width: 50%;
    p {
        margin: 0;
    }
    @media (max-width: 1024px) {
        h3 {
            font-size: 32px;
        }
        p {
            width: 100%;
        }
    }
    @media (max-width: 780px) {
        width: 100%;
        margin-bottom: 470px;
    }
`;


export const ContainerForm = styled(ContainerCenter)`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(134, 146, 166, 0.2);
    box-shadow: 0px -4px 4px 0px rgba(233, 223, 255, 0.14);
    border-radius: 9px;
    padding: 40px 16px;
`;

export const PricesList = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;
    @media (max-width: 1024px) {
        justify-content: space-between;
    }
    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

export const ImageSoluction = styled.div`
    width: 100%;
    height: 800px;
    position: absolute;
    top: 50%;
    left: -50%;
    transform: translate(20%, -50%);
    z-index: -1;
    &::after {
        content: '';
        width: 200px;
        height: 100px;
        background-color: rgba(14, 13, 13, 1);
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
    }
    @media (max-width: 1024px) {
        height: 698px;   
        left: -45%;     
    }
    @media (max-width: 780px) {
        width: 300%;
        height: 470px;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        order: 1;
    }
`;