import React, { useEffect } from "react";
import WhatsAppChat from "../../../components/chat/WhatsAppChat";
import "./ChatPageWhatsApp.css";

interface ChatPageProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

const ChatPageWhatsApp: React.FC<ChatPageProps> = ({ user_data, setTitleSection }) => {

  useEffect(() => {
    setTitleSection("WhatsApp Chat");
  }, [setTitleSection]);

  return (
    <div className="chat-page-container">
      <WhatsAppChat user_data={user_data} />
    </div>
  );
};

export default ChatPageWhatsApp;