import React from "react";
import { Form, Input, Button, Row, Col } from "antd";

interface ReservationSystemProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
  businessModel: string;
}

export default function ReservationSystem({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
  businessModel,
}: ReservationSystemProps) {
  const [form] = Form.useForm();

  // Determinar si el modelo es "medical"
  const isMedical = businessModel === "medical";

  // Cambiar dinámicamente los labels
  const reservationPolicyLabel = isMedical
    ? "Política de agendamiento de citas"
    : "Política de reservas";

  const cancellationPolicyLabel = isMedical
    ? "Política de cancelación de citas"
    : "Política de cancelaciones";

  const handleSubmit = (values: any) => {
    updateFormData(values);
    nextStep();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
      style={{ width: "100%" }}
    >
      {/* Solo para restaurantes: Capacidad y Mesas */}
      {businessModel === "restaurant" && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Capacidad total del local"
              name="capacity"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese la capacidad total del local",
                },
              ]}
              tooltip="Indique la cantidad máxima de clientes que el local puede acomodar, considerando solo las áreas disponibles para clientes."
            >
              <Input size="large" type="number" placeholder="Ej: 100 personas" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Número de mesas"
              name="tables"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el número de mesas",
                },
              ]}
              tooltip="Indique el número total de mesas disponibles en el establecimiento."
            >
              <Input size="large" type="number" placeholder="Ej: 20 mesas" />
            </Form.Item>
          </Col>
        </Row>
      )}

      {/* Para restaurante o medical-office */}
      {(businessModel === "restaurant" || businessModel === "medical") && (
        <>
          <Form.Item
            label={reservationPolicyLabel}
            name="reservationPolicy"
            rules={[
              {
                required: true,
                message: isMedical
                  ? "Por favor ingrese la política de agendamiento de citas"
                  : "Por favor ingrese la política de reservas",
              },
            ]}
            tooltip={
              isMedical
                ? "Detalla las reglas para aceptar y modificar citas médicas en tu establecimiento."
                : "Detalla las reglas para aceptar y modificar reservas en tu establecimiento."
            }
          >
            <Input.TextArea
              size="large"
              placeholder={
                isMedical
                  ? "Ej: Tiempo de antelación para agendar citas, condiciones de reprogramación, etc."
                  : "Ej: Tiempo de antelación para reservar, depósito requerido, condiciones de modificación, etc."
              }
            />
          </Form.Item>

          <Form.Item
            label={cancellationPolicyLabel}
            name="cancellationPolicy"
            rules={[
              {
                required: true,
                message: isMedical
                  ? "Por favor ingrese la política de cancelación de citas"
                  : "Por favor ingrese la política de cancelaciones",
              },
            ]}
            tooltip={
              isMedical
                ? "Especifica las condiciones bajo las cuales los pacientes pueden cancelar una cita y las penalizaciones asociadas."
                : "Especifica las condiciones bajo las cuales los clientes pueden cancelar una reserva y las penalizaciones asociadas."
            }
          >
            <Input.TextArea
              size="large"
              placeholder={
                isMedical
                  ? "Ej: Tiempo de antelación para cancelar la cita, penalizaciones, condiciones de reembolso, etc."
                  : "Ej: Tiempo de antelación para cancelar, penalizaciones, condiciones de reembolso, etc."
              }
            />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}