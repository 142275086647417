import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { signIn, resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import styles from "./Login.module.css";
import logo from "../../assets/img/landingPage/logo_login.webp";

enum ForgotPasswordFlow {
  Login = 1,
  RequestEmail,
  ConfirmReset,
}

const Login: React.FC = () => {
  // Estados para el login y el flujo de recuperación
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState<ForgotPasswordFlow>(ForgotPasswordFlow.Login);
  
  // Estados usados en el flujo de recuperar contraseña
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isSendingResetCode, setIsSendingResetCode] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  // Función para validar la complejidad de la contraseña
  const isPasswordValid = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  // Handler para iniciar sesión (flujo principal)
  const handleSignIn = async () => {
    if (!email.trim() || !password.trim()) {
      message.error("Por favor, complete todos los campos para iniciar sesión.");
      return;
    }
    // Validación de formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      message.error("Por favor, ingresa un correo electrónico válido.");
      return;
    }
    try {
      setIsLoggingIn(true);
      await signIn({ username: email, password });
      message.success("Inicio de sesión exitoso.");
      window.location.href = "/";
      window.location.reload();
    } catch (error: any) {
      console.error("Login error:", error);
      message.error(`Error de inicio de sesión: ${error.message}`);
    } finally {
      setIsLoggingIn(false);
    }
  };

  // Handler para pasar del login a la solicitud del correo (olvidé mi contraseña)
  const handleForgotPassword = () => {
    // Si el usuario ya ha ingresado un correo, lo tomamos; de lo contrario, quedará vacío.
    setForgotPasswordStep(ForgotPasswordFlow.RequestEmail);
  };

  // Handler para enviar el correo y solicitar el código de verificación
  const handleSendResetCode = async () => {
    if (!email.trim()) {
      message.error("Por favor, ingresa tu correo electrónico para recuperar la contraseña.");
      return;
    }
    // Validación de formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      message.error("Por favor, ingresa un correo electrónico válido.");
      return;
    }
    try {
      setIsSendingResetCode(true);
      const output = await resetPassword({ username: email });
      const { nextStep } = output;
      if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
        message.success(
          `Se ha enviado un código de verificación a ${email}. Por favor, revise su bandeja de entrada (y carpeta de spam) para continuar.`
        );
        setForgotPasswordStep(ForgotPasswordFlow.ConfirmReset);
      }
    } catch (error: any) {
      console.error("Reset Password error:", error);
      message.error(`Error al solicitar restablecimiento de contraseña: ${error.message}`);
    } finally {
      setIsSendingResetCode(false);
    }
  };

  // Handler para confirmar el código y actualizar la contraseña
  const handleConfirmResetPassword = async () => {
    if (!verificationCode.trim()) {
      message.error("El código de verificación no puede estar vacío.");
      return;
    }
    if (!newPassword.trim()) {
      message.error("La nueva contraseña no puede estar vacía.");
      return;
    }
    if (!isPasswordValid(newPassword)) {
      message.error(
        "La nueva contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial."
      );
      return;
    }
    try {
      setIsChangingPassword(true);
      await confirmResetPassword({
        username: email,
        confirmationCode: verificationCode,
        newPassword,
      });
      message.success("Contraseña restablecida correctamente.");
      // Regresa al flujo de login y limpia los campos de recuperación
      setForgotPasswordStep(ForgotPasswordFlow.Login);
      setVerificationCode("");
      setNewPassword("");
    } catch (error: any) {
      console.error("Confirm Reset Password error:", error);
      message.error(`Error al restablecer la contraseña: ${error.message}`);
    } finally {
      setIsChangingPassword(false);
    }
  };

  // Maneja la tecla "Enter" para enviar el formulario en el paso actual
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      switch (forgotPasswordStep) {
        case ForgotPasswordFlow.Login:
          handleSignIn();
          break;
        case ForgotPasswordFlow.RequestEmail:
          handleSendResetCode();
          break;
        case ForgotPasswordFlow.ConfirmReset:
          handleConfirmResetPassword();
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={styles["login-container"]} onKeyDown={handleKeyDown}>
      <div className={styles["login-form"]}>
        <img src={logo} alt="Logo" className={styles.logo} />
        {forgotPasswordStep === ForgotPasswordFlow.Login && (
          <>
            <h1 className={styles.title}>Iniciar Sesión</h1>
            <div className={styles["form-field"]}>
              <label htmlFor="email" className={styles.label}>
                Correo Electrónico
              </label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ingresa tu correo electrónico"
              />
            </div>
            <div className={styles["form-field"]}>
              <label htmlFor="password" className={styles.label}>
                Contraseña
              </label>
              <Input.Password
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Ingresa tu contraseña"
              />
            </div>
            <Button
              type="primary"
              loading={isLoggingIn}
              onClick={handleSignIn}
              block
              className={styles["login-btn-primary"]}
            >
              Iniciar Sesión
            </Button>
            <Button
              type="link"
              onClick={() => (window.location.href = "/onboarding")}
              block
              className={styles["ant-btn-link"]}
            >
              Registrarse
            </Button>
            <Button
              type="link"
              onClick={handleForgotPassword}
              block
              className={styles["ant-btn-link"]}
            >
              ¿Olvidaste tu contraseña?
            </Button>
          </>
        )}

        {forgotPasswordStep === ForgotPasswordFlow.RequestEmail && (
          <>
            <h1 className={styles.title}>Recuperar Contraseña</h1>
            <div className={styles["form-field"]}>
              <label htmlFor="forgotEmail" className={styles.label}>
                Correo Electrónico
              </label>
              <Input
                id="forgotEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ingresa tu correo electrónico"
              />
            </div>
            <Button
              type="primary"
              loading={isSendingResetCode}
              onClick={handleSendResetCode}
              block
              className={styles["login-btn-primary"]}
            >
              Enviar Código
            </Button>
            <Button
              type="link"
              onClick={() => setForgotPasswordStep(ForgotPasswordFlow.Login)}
              block
              className={styles["ant-btn-link"]}
            >
              Volver a Iniciar Sesión
            </Button>
          </>
        )}

        {forgotPasswordStep === ForgotPasswordFlow.ConfirmReset && (
          <>
            <h1 className={styles.title}>Código de Verificación</h1>
            <p>
              Se ha enviado un código de verificación a{" "}
              <strong>{email}</strong>. Revise su bandeja de entrada (y carpeta de spam) para continuar.
            </p>
            <div className={styles["form-field"]}>
              <label htmlFor="verificationCode" className={styles.label}>
                Código de Verificación
              </label>
              <Input
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Ingresa el código recibido"
              />
            </div>
            <div className={styles["form-field"]}>
              <label htmlFor="newPassword" className={styles.label}>
                Nueva Contraseña
              </label>
              <Input.Password
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Ingresa tu nueva contraseña"
              />
            </div>
            <Button
              type="primary"
              loading={isChangingPassword}
              onClick={handleConfirmResetPassword}
              block
              className={styles["login-btn-primary"]}
            >
              Restablecer Contraseña
            </Button>
            <Button
              type="link"
              onClick={() => setForgotPasswordStep(ForgotPasswordFlow.Login)}
              block
              className={styles["ant-btn-link"]}
            >
              Volver a Iniciar Sesión
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;