import React, { useState, useEffect } from "react";
import { Card, Row, Col, message, Modal } from "antd";
import TableComponent from "../../../components/table/table";
import { get, post, put, del } from "../../../Api/http/httpClient";
import PackagesModal from "../../../components/modals/PackagesModal";
import dayjs from "dayjs";

const { confirm } = Modal;

interface PackagesDataProps {
  user_data: any;
  setTitleSection: (title: string) => void;
}

// Función para formatear fechas
const formatDate = (dateString: string) => {
  return dayjs(dateString).format("DD/MM/YYYY hh:mm A");
};

const PackagesData: React.FC<PackagesDataProps> = ({ user_data, setTitleSection }) => {
  const [data, setData] = useState<any[]>([]);
  const [establishments, setEstablishments] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortOrder, setSortOrder] = useState("ascend");
  const [sortColumn, setSortColumn] = useState("plan_name");
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any | null>(null);
  const [action, setAction] = useState<any | null>(null);
  const [formData, setFormData] = useState({
    establishment_id: "",
    plan_id: "",
    plan_name: "",
    initial_messages: "",
    remaining_messages: "",
    autoRenew: false,
    is_active: false,
    created_at: "",
    expiry_date: "",
  });

  useEffect(() => {
    setTitleSection("Paquetes");
  }, [setTitleSection]);

  const headers_table = {
    plan_id: "ID del Plan",
    package_id: "ID del Paquete",
    establishment_id: "ID del Establecimiento",
    created_at: "Fecha de Creación",
    expiry_date: "Fecha de Expiración",
    plan_name: "Nombre del Plan",
    initial_messages: "Mensajes Iniciales",
    remaining_messages: "Mensajes Restantes",
    autoRenew: "Renovación Automática",
    is_active: "Estado",
  };

  const getPackagesData = async () => {
    setLoading(true);
    setDeleting(null);
    try {
      const res = await get(
        `packages-plans?establishment_id=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&page=${page}&page_size=${itemsPerPage}&search=${search}&sort_by=${sortColumn}&order=${sortOrder}`
      );

      if (res.answer && res.items) {
        const formattedPackageItems = res.items.map((packageItem: any) => ({
          plan_id: packageItem.plan_id || null,
          package_id: packageItem.package_id || null,
          establishment_id: packageItem.establishment_id || null,
          created_at: formatDate(packageItem.created_at) || "N/A",
          expiry_date: formatDate(packageItem.expiry_date) || "N/A",
          plan_name: packageItem.plan_name || "N/A",
          initial_messages: packageItem.initial_messages || "0",
          remaining_messages: packageItem.remaining_messages || "0",
          autoRenew: packageItem.autoRenew ? "Sí" : "No",
          is_active: packageItem.is_active,
        }));
        setData(formattedPackageItems);

        const totalItems = res.total_items;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      } else {
        message.error("No se encontraron paquetes.");
      }
    } catch (error) {
      console.log(error);
      message.error("Error al obtener los datos de paquetes.");
    } finally {
      setLoading(false);
    }
  };

  const fetchEstablishments = async () => {
    try {
      const res = await get(`restaurant-onboarding?fetch_all=true`);
      if (res.answer) setEstablishments(res.items);
    } catch (error) {
      message.error("Error al obtener establecimientos.");
    }
  };

  const fetchPlans = async () => {
    try {
      const res = await get(`plans?fetch_all=true`);
      if (res.answer) setPlans(res.items);
    } catch (error) {
      message.error("Error al obtener planes.");
    }
  };

  useEffect(() => {
    getPackagesData();
  }, [page, itemsPerPage, search, sortOrder, sortColumn]);

  useEffect(() => {
    fetchEstablishments();
    fetchPlans();
  }, []);

  const setActionHandler = (action: { type: string; data?: any }) => {
    if (action.type === "edit") {
      setSelectedPackage(action.data);
      setFormData({
        establishment_id: action.data.establishment_id,
        plan_id: action.data.plan_id,
        plan_name: action.data.plan_name,
        initial_messages: action.data.initial_messages,
        remaining_messages: action.data.remaining_messages,
        autoRenew: action.data.autoRenew,
        is_active: action.data.is_active,
        created_at: action.data.created_at,
        expiry_date: action.data.expiry_date,
      });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "delete") {
      setAction(action);
      showDeleteConfirm(action.data);
    } else if (action.type === "create") {
      setFormData({
        establishment_id: "",
        plan_id: "",
        plan_name: "",
        initial_messages: "",
        remaining_messages: "",
        autoRenew: false,
        is_active: false,
        created_at: "",
        expiry_date: "",
      });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "reload") {
      getPackagesData();
    }
  };

  const showDeleteConfirm = (packageItem: any) => {
    confirm({
      title: "¿Estás seguro de eliminar este paquete?",
      content: `Paquete: ${packageItem.plan_name}`,
      okText: "Sí, eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleDelete(packageItem);
      },
      onCancel() {
        setDeleting(null);
      },
    });
  };

  const handleDelete = async (packageItem: any) => {
    setDeleting(packageItem.package_id);

    try {
      const res = await del("packages-plans", {
        establishment_id: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        package_id: packageItem.package_id,
        plan_name: packageItem.plan_name,
      });

      if (res && res.answer) {
        message.success("Paquete eliminado correctamente.");
        getPackagesData();
      } else {
        message.error("Error al eliminar el paquete.");
      }
    } catch (error) {
      message.error("Error al eliminar el paquete.");
    } finally {
      setDeleting(null);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPackage(null);
    setAction(null);
  };

  const handleSave = async () => {
    setModalLoading(true);
    try {
      const { establishment_id, plan_id, ...restOfFormData } = formData; // Excluir establishment_id y plan_id
      const res = await post("packages-plans", {
        establishment_id,
        plan_id,
        ...restOfFormData,
      });

      if (res && res.answer) {
        message.success("Paquete creado correctamente.");
        handleCloseModal();
        getPackagesData();
      } else {
        message.error("Error al crear el paquete.");
      }
    } catch (error) {
      message.error("Error al crear el paquete.");
    } finally {
      setModalLoading(false);
    }
  };

  const handleUpdate = async () => {
    setModalLoading(true);
    try {
      if (selectedPackage) {
        const { establishment_id, plan_id, ...restOfFormData } = formData; // Excluir establishment_id y plan_id
        const res = await put("packages-plans", {
          establishment_id,
          plan_id,
          package_id: selectedPackage.package_id,
          ...restOfFormData,
        });

        if (res && res.answer) {
          const updatedData = data.map((item) =>
            item.package_id === selectedPackage.package_id ? { ...item, ...formData } : item
          );
          setData(updatedData);
          message.success("Paquete actualizado correctamente.");
          handleCloseModal();
          getPackagesData();
        } else {
          message.error("Error al actualizar el paquete.");
        }
      } else {
        message.error("No se seleccionó ningún paquete para actualizar.");
      }
    } catch (error) {
      message.error("Error al actualizar el paquete.");
    } finally {
      setModalLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSelectChange = (value: string, field: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleCheckboxChange = (checked: boolean, field: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: checked,
    }));
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={23} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Card bordered={false} style={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: "0px" }}>
          <TableComponent
            data={data}
            headers_table={headers_table}
            loading={loading}
            setSearch={setSearch}
            setAction={setActionHandler}
            totalPages={totalPages}
            setPage={setPage}
            page={page}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            setSortOrder={setSortOrder}
            setSortColumn={setSortColumn}
            deleting={deleting}
          />
        </Card>
      </Col>

      <PackagesModal
        open={modalOpen}
        onClose={handleCloseModal}
        formData={formData}
        establishments={establishments}
        plans={plans}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        handleCheckboxChange={handleCheckboxChange}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        action={action}
        loading={modalLoading}
      />
    </Row>
  );
};

export default PackagesData;