import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  message,
  Typography,
  Upload,
  Button,
  Switch,
  Modal,
  Avatar,
  Descriptions,
  List,
  Tag,
  Badge,
  Space,
  Skeleton,
} from "antd";
import {
  CrownOutlined,
  SmileOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  SiVisa,
  SiMastercard,
  SiAmericanexpress,
  SiDiscover,
} from "react-icons/si";
import styles from "./UserProfile.module.css";
import ChangePasswordModal from "../../../components/modals/ChangePasswordModal";
import ChangePaymentMethodModal from "../../../components/modals/ChangePaymentMethodModal";
import { get, put } from "../../../Api/http/httpClient";
import mastercard from "../../../assets/img/dashboard/mastercard-logo.png";
import visa from "../../../assets/img/dashboard/visa-logo.png";

const { Text } = Typography;

interface UserProfileProps {
  user_data: {
    email: string;
    phone_number: string;
    family_name: string;
    address: string;
    "custom:establishment": string;
    "custom:establishment_id": string;
    sub: string;
    "custom:plan": string;
  };
  setTitleSection: (title: string) => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ user_data, setTitleSection }) => {
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState("Free");
  const [logoUrl, setLogoUrl] = useState("https://www.bloxai.co/logo_horizontal.png");
  const [fileList, setFileList] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [autoRenew, setAutoRenew] = useState(false);
  const [packageData, setPackageData] = useState<any>(null);
  const [savePicture, setSavePicture] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTitleSection("Perfil de usuario");
  }, [setTitleSection]);

  const fetchEstablishmentData = async () => {
    setLoading(true);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
      } else {
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  const fetchPackageData = async () => {
    try {
      const response = await get(
        `packages-plans?establishment_id=${user_data["custom:establishment_id"]}&only_active=true`
      );
      if (response.answer) {
        setPackageData(response.item);
      } else {
        setPackageData(null);
      }
    } catch (error) {
      console.error("Error fetching package data:", error);
      message.error("Error al obtener los datos del paquete.");
    }
  };

  const fetchAutomaticPaymentData = async () => {
    try {
      const response = await get(
        `automatic-payment?establishment_id=${user_data["custom:establishment_id"]}`
      );
      if (response.answer) {
        const payment = response.data[0];
        setPaymentData({
          ...payment,
          formattedDate: formatDate(payment.next_billing_date),
        });
        setAutoRenew(payment.autoRenew);
      } else {
        setPaymentData(null);
      }
    } catch (error) {
      console.error("Error fetching automatic payment data:", error);
      message.error("Error al obtener los datos de pago automático.");
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const getCardIcon = (cardName: string) => {
    if (cardName.toLowerCase().includes("visa"))
      return <SiVisa style={{ width: 24, height: 24 }} />;
    if (cardName.toLowerCase().includes("mastercard"))
      return <SiMastercard style={{ width: 24, height: 24 }} />;
    if (
      cardName.toLowerCase().includes("american express") ||
      cardName.includes("amex")
    )
      return <SiAmericanexpress style={{ width: 24, height: 24 }} />;
    if (cardName.toLowerCase().includes("discover"))
      return <SiDiscover style={{ width: 24, height: 24 }} />;
    return null;
  };

  const formatCardNumber = (cardNumber: string) => {
    const lastFourDigits = cardNumber.split("-")[1];
    return `**** **** **** ${lastFourDigits}`;
  };

  const handleLogoUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogoUrl(e.target?.result as string);
    };
    reader.readAsDataURL(file);
    return false;
  };

  useEffect(() => {
    setPlan(user_data["custom:plan"] === "0" ? "Free" : "Premium");
    fetchEstablishmentData();
    fetchAutomaticPaymentData();
    if (user_data["custom:plan"] !== "0") {
      fetchPackageData(); // Solo obtener datos del paquete si el plan es Premium
    }
  }, []);

  const handlePlanChange = () => {
    if (plan === "Free") {
      navigate("/plans");
    } else {
      if (autoRenew) {
        confirmPlanChange();
      } else {
        message.info(
          `Tu plan ya fue procesado para pasar a un plan gratuito el ${formatDate(
            packageData.expiry_date
          )}.`
        );
      }
    }
  };

  const handlePaymentMethodSubmit = async (values: {
    cardToken: string;
    nameCard: string;
    expires_at: string;
  }): Promise<any> => {
    try {
      // Preparar los datos para enviar en el cuerpo de la solicitud
      const payload = {
        cardToken: values.cardToken,
        nameCard: values.nameCard,
        expires_at: values.expires_at,
        packageData: packageData,
        update_payment_method: true,
        establishmentID: user_data["custom:establishment_id"],
      };

      // Realizar la solicitud PUT
      const response = await put("recurring-billing", payload);

      fetchAutomaticPaymentData();
      return response;
    } catch (error) {
      console.error("Error al actualizar el método de pago:", error);
      return null;
    }
  };

  const handleCardChange = () => {
    setIsPaymentModalVisible(true);
  };

  const confirmAutoRenewChange = () => {
    Modal.confirm({
      title: `¿Estás seguro de que quieres ${
        autoRenew ? "desactivar" : "activar"
      } la renovación automática?`,
      content: `La renovación automática se ${
        autoRenew ? "cancelará" : "activará"
      } para tu método de pago.`,
      okText: "Sí",
      cancelText: "No",
      onOk: toggleAutoRenew,
    });
  };

  const confirmPlanChange = () => {
    Modal.confirm({
      title: "¿Estás seguro de que quieres cambiar a un plan gratuito?",
      content:
        "Al cambiar al plan gratuito, perderás todos tus beneficios actuales. ¿Deseas continuar?",
      okText: "Sí, cambiar",
      cancelText: "No, cancelar",
      onOk: updateSubscriptionPlan,
    });
  };

  const updateSubscriptionPlan = async () => {
    try {
      const payload = {
        subscription_action: !autoRenew ? "reactivate" : "cancel",
        packageData: packageData,
        establishmentID: user_data["custom:establishment_id"],
      };

      const response = await put("recurring-billing", payload);

      if (response.answer) {
        setAutoRenew(!autoRenew);
        message.success(
          `Has cambiado al plan ${
            !autoRenew ? "gratuito" : "actual"
          } correctamente.`
        );
      } else {
        message.error(
          "Hubo un problema al intentar cambiar el plan. Por favor, intenta nuevamente."
        );
      }
    } catch (error) {
      message.error("Error al intentar cambiar el plan. Inténtalo más tarde.");
    }
  };

  const toggleAutoRenew = async () => {
    try {
      const payload = {
        subscription_action: !autoRenew ? "reactivate" : "cancel",
        packageData: packageData,
        establishmentID: user_data["custom:establishment_id"],
      };

      const response = await put("recurring-billing", payload);

      if (response.answer) {
        setAutoRenew(!autoRenew);
        message.success(
          `Renovación automática ${
            !autoRenew ? "activada" : "desactivada"
          } correctamente.`
        );
      } else {
        message.error("Hubo un problema al actualizar la renovación automática.");
      }
    } catch (error) {
      message.error("Error al actualizar la renovación automática.");
    }
  };

  const suscriptionData = packageData
    ? [
        {
          title: packageData.plan_name,
          description:
            plan === "Free"
              ? "Disfruta de las funcionalidades básicas de tu establecimiento."
              : "Tienes acceso a todas las funcionalidades avanzadas.",
          avatar:
            plan === "Premium" ? (
              <CrownOutlined style={{ fontSize: "24px", color: "gold" }} />
            ) : (
              <SmileOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
            ),
          is_active: packageData.is_active,
        }
      ]
    : [];

  return (
    <>
      <div className="profile-nav-bg"></div>
      <Card
        className="card-profile-head"
        styles={{ 
          body: {
            display: "none"
          }
        }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Badge
                  count={
                    <Upload
                      beforeUpload={handleLogoUpload}
                      fileList={fileList}
                      onChange={({ fileList: newFileList }) => {
                        setFileList(newFileList);
                        setSavePicture(true);
                      }}
                      showUploadList={false}
                    >
                      <Button
                        icon={<EditOutlined />}
                        type="link"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50%",
                        }}
                      ></Button>
                    </Upload>
                  }
                >
                  {loading ? (
                    <Skeleton.Avatar active size={74} shape="square" />
                  ) : (
                    <Avatar size={74} shape="square" src={logoUrl} />
                  )}
                </Badge>
                <div className="avatar-info">
                  {loading ? (
                    // Aquí se agrupan los Skeleton en un contenedor con flex-direction column
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: 120, marginBottom: 8 }}
                      />
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: 150 }}
                      />
                    </div>
                  ) : (
                    <>
                      <h4 className="font-semibold m-0">{user_data.family_name}</h4>
                      <p style={{ textTransform: "capitalize" }}>
                        {user_data["custom:establishment"]}
                      </p>
                    </>
                  )}
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Space>                  
                <Button
                  type="text"
                  onClick={() => setIsModalVisible(true)}
                  className={styles.changePasswordButton}
                >
                  <EditOutlined />
                  Cambiar Contraseña
                </Button>
                <Button
                  type="primary"
                  className={styles.saveButton}
                  disabled={!savePicture}
                  onClick={() => {
                    message.success("Logo guardado localmente");
                    setSavePicture(false);
                  }}
                >
                  <SaveOutlined />
                  Guardar
                </Button>
              </Space>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        {/* Tarjeta de Descripción */}
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Descripción</h6>}
            className="header-solid h-full card-profile-information"
            styles={{
              body: {
                paddingTop: 0,
                paddingBottom: 16,
              },
            }}
            loading={loading}
          >
            {loading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <>
                <p className="text-dark">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste incidunt dolorum cum mollitia similique beatae vitae. Asperiores assumenda voluptatem voluptas reprehenderit distinctio dolor, illum et magnam odio.
                </p>
                <hr className="my-25" />
                <Descriptions title="Datos del Usuario">
                  <Descriptions.Item label="Nombre" span={3}>
                    {user_data.family_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Teléfono" span={3}>
                    {user_data.phone_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Correo" span={3}>
                    {user_data.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Dirección" span={3}>
                    {user_data.address}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
          </Card>
        </Col>

        {/* Tarjeta de Suscripción */}
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Suscripción</h6>}
            className="header-solid h-full card-profile-information"
            extra={
              <Button
                type="text"
                onClick={handlePlanChange}
                className={styles.planButton}
              >
                <EditOutlined />
                {plan === "Free" ? "Actualizar a Premium" : "Cambiar a Free"}
              </Button>
            }
            styles={{
              body: {
                paddingTop: 0,
                paddingBottom: 16,
              },
            }}
            loading={loading}
          >
            {loading ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : (
              <>
                <List
                  itemLayout="horizontal"
                  dataSource={suscriptionData}
                  split={false}
                  className="conversations-list"
                  renderItem={(item) => (
                    <List.Item
                      extra={[
                        <Tag
                          color={item.is_active === "Activo" ? "green" : "red"}
                          key="tag"
                        >
                          {item.is_active}
                        </Tag>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar shape="square" size={48} src={item.avatar} />
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
                <hr className="my-25" />
                {packageData && (
                  <Descriptions title="Detalle de la Suscripción">
                    <Descriptions.Item label="Fecha de Expiración" span={3}>
                      {formatDate(packageData.expiry_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Renovación Automatica" span={3}>
                      {packageData.autoRenew ? "Activada" : "Desactivada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cantidad de Mensajes" span={3}>
                      {packageData.initial_messages}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cantidad de Mensajes Restantes" span={3}>
                      {packageData.remaining_messages}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </>
            )}
          </Card>
        </Col>

        {/* Tarjeta de Método de Pago */}
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Método de pago</h6>}
            className="header-solid h-full card-profile-information"
            extra={
              <Button
                type="text"
                onClick={handleCardChange}
                className={styles.paymentButton}
              >
                <EditOutlined />
                Cambiar Tarjeta
              </Button>
            }
            styles={{
              body: {
                paddingTop: 0,
                paddingBottom: 16,
              },
            }}
            loading={loading}
          >
            {loading ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : (
              paymentData && (
                <>
                  <Card className="payment-method-card">
                    {/* {getCardIcon(paymentData.name_card)} */}
                    <img src={visa} alt="visa" />
                    <h6 className="card-number">
                      {formatCardNumber(paymentData.name_card)}
                    </h6>
                  </Card>
                  <hr className="my-25" />
                  <ul className="list settings-list">
                    <li>
                      <Switch checked={autoRenew} onChange={confirmAutoRenewChange} />
                      <span>Renovación Automática</span>
                    </li>
                  </ul>
                  <Descriptions>
                    <Descriptions.Item label="Próxima Fecha de Facturación" span={3}>
                      {autoRenew ? paymentData.formattedDate : "Renovación automática desactivada"}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )
            )}
          </Card>
        </Col>
      </Row>

      <ChangePaymentMethodModal
        isModalVisible={isPaymentModalVisible}
        setIsModalVisible={setIsPaymentModalVisible}
        onSubmit={handlePaymentMethodSubmit}
      />

      <ChangePasswordModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default UserProfile;