// paySchema.js
import * as yup from "yup";

export const paySchema = yup.object({
  cardNumber: yup
    .string()
    .required("El número de tarjeta es obligatorio")
    .matches(/^[0-9 ]{16,19}$/, "El número de tarjeta es inválido") // Permite espacios y entre 16 y 19 caracteres
    .test("is-valid-length", "El número de tarjeta debe tener 16 dígitos", function (value) {
      const cleanValue = value.replace(/\s+/g, ""); // Remueve espacios
      return cleanValue.length === 16; // Verifica que el número de tarjeta tenga exactamente 16 dígitos
    }),
  
  expiryDate: yup
    .string()
    .required("La fecha de expiración es obligatoria")
    .test("valid-month", "El mes es inválido", function (value) {
      if (!value) return false;
      const [month] = value.split("/").map((item) => parseInt(item, 10));
      return month >= 1 && month <= 12;
    })
    .test("is-future-date", "La fecha de expiración debe ser en el futuro", function (value) {
      if (!value) return false;
      const currentDate = new Date();
      const [month, year] = value.split("/").map((item) => parseInt(item, 10));
      const expiryDate = new Date(year + 2000, month, 1);
      return expiryDate > currentDate;
    }),
  
  // Validamos que el nombre tenga al menos 5 caracteres.
  name: yup
    .string()
    .required("El nombre es obligatorio")
    .min(5, "El nombre debe tener al menos 5 caracteres"),
  
  cvv: yup
    .string()
    .matches(/^[0-9]{3,4}$/, "El CVV es inválido")
    .required("El CVV es obligatorio"),
});