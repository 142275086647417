import { parseMonthYear, sortByChronology } from "../../../utils/year_monthly_interactions";

// Helper para convertir el índice de mes (1 a 12) a su abreviación.
const MONTHS_LABELS = [
  "",   
  "Ene", 
  "Feb", 
  "Mar", 
  "Abr", 
  "May", 
  "Jun", 
  "Jul", 
  "Ago", 
  "Sep", 
  "Oct", 
  "Nov", 
  "Dic", 
];

export const getEChartConfig = (data: any) => {
  const yearMonthly = data?.year_monthly_interactions || {};

  // Extraemos las llaves (p.ej. ["Dec 2024", "Jan 2025", ...]).
  let keys = Object.keys(yearMonthly);

  // Si no llega nada, devolvemos un config con noData
  if (keys.length === 0) {
    return {
      series: [],
      options: {
        chart: {
          type: "bar" as const,
          toolbar: { show: false },
        },
        noData: {
          text: "No hay datos para graficar",
          align: "center" as const,      
          verticalAlign: "middle" as const, 
          style: {
            color: "#fff",
            fontSize: "14px",
          },
        },        
      },
    };
  }

  // Ordenamos las llaves cronológicamente (de más antiguo a más reciente).
  keys = sortByChronology(keys);

  // Construimos las categorías y el arreglo data.
  const categories: string[] = [];
  const seriesData: number[] = [];

  keys.forEach((key) => {
    // Parseamos la llave para obtener su monthIndex y year
    const { monthIndex, year } = parseMonthYear(key);

    // Convertimos el monthIndex al label corto (por ejemplo, "Feb")
    const label = `${MONTHS_LABELS[monthIndex]}`;
    // Para mostar tambien el año, usariamos: `label = `${MONTHS_LABELS[monthIndex]} ${year}`

    categories.push(label);
    seriesData.push(yearMonthly[key] || 0);
  });

  return {
    series: [
      {
        name: "Interacciones",
        data: seriesData,
        color: "#fff",
      },
    ],
    options: {
      chart: {
        type: "bar" as const,
        width: "100%",
        height: "auto",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories,
        labels: {
          show: true,
          align: "right" as const,
          style: {
            // Genera un array del mismo tamaño que categories con color "#fff"
            colors: new Array(categories.length).fill("#fff"),
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right" as const,
          style: {
            colors: new Array(categories.length).fill("#fff"),
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  };
};