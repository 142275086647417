import React from "react";
import { Modal, Form, Input, Row, Col } from "antd";

interface PaymentDetails {
  plan_id: string;
  establishment_id: string;
  currency: string;
  finalized_at: string;
  status: string;
  name_establishment: string;
  full_name: string;
  email: string;
  creation_date: string;
  reference: string;
  plan_messages: number;
  plan_title: string;
  phone_number: string;
  amount: string;
  payment_reference: string;
  payment_method_type: string;
}

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  paymentDetails: PaymentDetails | null;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  open,
  onClose,
  paymentDetails,
}) => {
  console.log(paymentDetails);
  if (!paymentDetails) return null;

  return (
    <Modal
      title={`Detalles de la Transacción: ${paymentDetails.payment_reference}`}
      visible={open}
      onCancel={onClose}
      footer={null}
      width={700} // Ajuste del ancho para mejor distribución
    >
      <Form layout="vertical">
        {/* Referencias */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Referencia de Pago">
              <Input value={paymentDetails.payment_reference} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Referencia">
              <Input value={paymentDetails.reference} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Establecimiento */}
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Establecimiento">
              <Input value={paymentDetails.name_establishment} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Plan */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Título del Plan">
              <Input value={paymentDetails.plan_title} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Mensajes del Plan">
              <Input value={paymentDetails.plan_messages} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Monto y Estado */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Monto (COP)">
              <Input value={paymentDetails.amount} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Estado">
              <Input value={paymentDetails.status} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Método de Pago y Moneda */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Método de Pago">
              <Input value={paymentDetails.payment_method_type} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Moneda">
              <Input value={paymentDetails.currency} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Fechas */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Fecha de Finalización">
              <Input value={paymentDetails.finalized_at} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Fecha de Transacción">
              <Input
                value={new Date(paymentDetails.creation_date).toLocaleString()}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Contacto */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Número de Teléfono">
              <Input value={paymentDetails.phone_number} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Correo Electrónico">
              <Input value={paymentDetails.email} disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* Nombre Completo */}
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Nombre Completo">
              <Input value={paymentDetails.full_name} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PaymentModal;