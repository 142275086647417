import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Button,
  Spin,
  message,
  Typography,
  Switch,
  TimePicker
} from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { get, put } from "../../../Api/http/httpClient"; // Importamos get y put
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import dayjs from "dayjs"; // Reemplazo de moment por dayjs
import "./Schedule.css"; // Importación del archivo de estilos

const { Text, Title } = Typography;

// Mapeo de días de inglés a español
const dayTranslations: { [key: string]: string } = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

const Schedule: React.FC<{ user_data: any, setTitleSection: (title: string) => void; }> = ({ user_data, setTitleSection }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [physicalPoints, setPhysicalPoints] = useState<any[]>([]);

  const screens = useBreakpoint();

  useEffect(() => {
    setTitleSection("Horarios del Establecimiento");
  }, [setTitleSection]);

  useEffect(() => {
    setTitleSection("Horarios del Establecimiento");
  }, [setTitleSection]);

  const daysOptions = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  // Función para convertir las horas del backend al formato interno (12 horas)
  const convertTimeFromBackend = (timeString: string) => {
    return dayjs(timeString, "hh:mm A");
  };

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
        // Si el establecimiento es e-commerce, usamos la información de puntos físicos
        if (user_data["custom:establishment"] === "e-commerce") {
          setPhysicalPoints(fetchedData.physicalPoints || []);
        } else {
          // Para otros establecimientos se usa la información de horarios
          const initialValues = {
            openTime: fetchedData.openTime ? convertTimeFromBackend(fetchedData.openTime) : null,
            closeTime: fetchedData.closeTime ? convertTimeFromBackend(fetchedData.closeTime) : null,
            specialHours: fetchedData.specialHours,
            specialOpenTime: fetchedData.specialOpenTime ? convertTimeFromBackend(fetchedData.specialOpenTime) : null,
            specialCloseTime: fetchedData.specialCloseTime ? convertTimeFromBackend(fetchedData.specialCloseTime) : null,
            openDays: fetchedData.openDays || [],
          };

          form.setFieldsValue(initialValues);
          setOriginalValues(initialValues);
        }
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some((key) => {
      if (
        (key === "openTime" ||
          key === "closeTime" ||
          key === "specialOpenTime" ||
          key === "specialCloseTime") &&
        originalValues[key] &&
        allValues[key]
      ) {
        return !originalValues[key].isSame(allValues[key], "minute");
      }
      return originalValues[key] !== allValues[key];
    });
    setHasChanges(isDifferent);
  };

  const formatTimeForBackend = (time: any) => {
    return time ? dayjs(time).format("hh:mm A") : null;
  };

  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (
        key === "openTime" ||
        key === "closeTime" ||
        key === "specialOpenTime" ||
        key === "specialCloseTime"
      ) {
        const originalTime = originalValues[key];
        const newTime = values[key];
        if (
          (originalTime && newTime && !originalTime.isSame(newTime, "minute")) ||
          (originalTime && !newTime) ||
          (!originalValues[key] && newTime)
        ) {
          acc[key] = formatTimeForBackend(newTime);
        }
      } else {
        if (values[key] !== originalValues[key]) {
          acc[key] = values[key];
        }
      }
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
      return;
    }

    try {
      setUpdating(true);
      await put("restaurant-onboarding", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        ...changes,
      });
      message.success("Horarios actualizados correctamente.");
      fetchEstablishmentData();
      setHasChanges(false);
    } catch (error) {
      message.error("Error al actualizar los horarios del establecimiento.");
    } finally {
      setUpdating(false);
    }
  };

  // Funciones para manejar la edición y eliminación de un punto físico
  const handleEditPhysicalPoint = (index: number) => {
    message.info(`Editar punto físico ${index + 1}`);
    // Aquí iría la lógica para editar (por ejemplo, abrir un modal)
  };

  const handleDeletePhysicalPoint = (index: number) => {
    message.info(`Eliminar punto físico ${index + 1}`);
    // Aquí iría la lógica para eliminar (por ejemplo, confirmación y llamada a la API)
  };

  // Función para agregar un nuevo punto físico (solo para e-commerce)
  const handleAddPhysicalPoint = () => {
    message.info("Agregar nuevo punto físico");
    // Aquí iría la lógica para agregar (por ejemplo, abrir un modal)
  };

  // Escuchamos el valor de specialHours para mostrar u ocultar los campos de horarios especiales
  const specialHoursValue = Form.useWatch("specialHours", form);

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card
          title={
            user_data["custom:establishment"] === "e-commerce"
              ? "Puntos Físicos y Horarios"
              : "Horarios del Establecimiento"
          }
          extra={
            user_data["custom:establishment"] === "e-commerce" && (
              <Button type="primary" onClick={handleAddPhysicalPoint}>
                Agregar Punto Físico
              </Button>
            )
          }
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: screens.xs ? "10px" : "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>Cargando datos del establecimiento...</Text>
            </div>
          ) : error ? (
            <div className="error-container">
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text className="error-message">
                Ocurrió un error al cargar los datos del establecimiento.
              </Text>
              <Button type="primary" size="large" className="retry-button" onClick={fetchEstablishmentData}>
                Reintentar
              </Button>
            </div>
          ) : (
            <>
              {user_data["custom:establishment"] !== "e-commerce" ? (
                <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
                  <Row gutter={24}>
                    <Col span={screens.md ? 12 : 24}>
                      <Form.Item
                        label="Hora de Apertura"
                        name="openTime"
                        rules={[{ required: true, message: "Por favor seleccione la hora de apertura" }]}
                      >
                        <TimePicker use12Hours format="hh:mm A" size="large" style={{ borderRadius: "8px", width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={screens.md ? 12 : 24}>
                      <Form.Item
                        label="Hora de Cierre"
                        name="closeTime"
                        rules={[{ required: true, message: "Por favor seleccione la hora de cierre" }]}
                      >
                        <TimePicker use12Hours format="hh:mm A" size="large" style={{ borderRadius: "8px", width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label="Días de Apertura"
                        name="openDays"
                        rules={[{ required: true, message: "Por favor seleccione los días de apertura" }]}
                      >
                        <Select mode="multiple" size="large" placeholder="Seleccione los días de apertura" style={{ borderRadius: "8px" }}>
                          {daysOptions.map((day) => (
                            <Select.Option key={day} value={day}>
                              {day}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item label="Horarios Especiales" name="specialHours" valuePropName="checked">
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>

                  {specialHoursValue && (
                    <Row gutter={24}>
                      <Col span={screens.md ? 12 : 24}>
                        <Form.Item label="Hora Especial de Apertura" name="specialOpenTime">
                          <TimePicker use12Hours format="hh:mm A" size="large" style={{ borderRadius: "8px", width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={screens.md ? 12 : 24}>
                        <Form.Item label="Hora Especial de Cierre" name="specialCloseTime">
                          <TimePicker use12Hours format="hh:mm A" size="large" style={{ borderRadius: "8px", width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      loading={updating}
                      disabled={!hasChanges}
                      style={{
                        borderRadius: "8px",
                        background: hasChanges ? "linear-gradient(45deg, #1890ff, #1d39c4)" : "gray",
                        border: "none",
                      }}
                    >
                      Guardar Cambios
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                // Para e-commerce, se muestra sólo la sección de puntos físicos (sin botón de guardar cambios)
                <div className="physicalPointsContainer">
                  <Title level={4} className="physicalPointsTitle">Puntos Físicos</Title>
                  {physicalPoints.length > 0 ? (
                    physicalPoints.map((point, index) => (
                      <Card key={index} className="physicalPointCard">
                        <p className="physicalPointDetail"><strong>Dirección:</strong> {point.address}</p>
                        <p className="physicalPointDetail"><strong>Teléfono:</strong> {point.phone}</p>
                        <p className="physicalPointDetail">
                          <strong>Días:</strong>{" "}
                          {point.days && point.days.length > 0
                            ? point.days.map((day: string) => dayTranslations[day.toLowerCase()] || day).join(", ")
                            : "No disponibles"}
                        </p>
                        <p className="physicalPointDetail">
                          <strong>Horario:</strong>{" "}
                          {point.schedule && point.schedule.length > 0
                            ? point.schedule.join(" - ")
                            : "No disponible"}
                        </p>
                        <div className="physicalPointActions">
                          <Button type="primary" className="edit-button" onClick={() => handleEditPhysicalPoint(index)}>
                            Editar
                          </Button>
                          <Button danger className="delete-button" onClick={() => handleDeletePhysicalPoint(index)}>
                            Eliminar
                          </Button>
                        </div>
                      </Card>
                    ))
                  ) : (
                    <Text>No se han configurado puntos físicos.</Text>
                  )}
                </div>
              )}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Schedule;