import { Form, Input, Button, Select, message } from "antd";
import { useState, useEffect } from "react";
import rawData from "../../utils/data/data.json";

// Definición de interfaces
interface RawCity {
  id: number;
  name: string;
  latitude?: string | null;
  longitude?: string | null;
}

interface RawState {
  id: number;
  name: string;
  state_code?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  cities: RawCity[];
}

interface RawCountry {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  phone_code: string;
  states: RawState[];
}

interface City {
  id: number;
  name: string;
  latitude?: string;
  longitude?: string;
}

interface State {
  id: number;
  name: string;
  state_code?: string;
  latitude?: string;
  longitude?: string;
  cities: City[];
}

interface Country {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  phone_code: string;
  states: State[];
}

interface Option {
  value: string;
  label: string;
}

interface BasicInfoProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  initialValues: any;
  businessModel: string;
}

// Normaliza los datos del JSON
const normalizeData = (data: RawCountry[]): Country[] => {
  return data.map((country) => ({
    ...country,
    states: country.states.map((state) => ({
      ...state,
      state_code: state.state_code ?? undefined,
      latitude: state.latitude ?? undefined,
      longitude: state.longitude ?? undefined,
      cities: state.cities.map((city) => ({
        ...city,
        latitude: city.latitude ?? undefined,
        longitude: city.longitude ?? undefined,
      })),
    })),
  }));
};

// Normalizamos los datos importados
const countriesData: Country[] = normalizeData(rawData as RawCountry[]);

export default function BasicInfo({
  updateFormData,
  nextStep,
  initialValues,
  businessModel,
}: BasicInfoProps) {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState<Option[]>([]);
  const [states, setStates] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const [statesDisabled, setStatesDisabled] = useState(true);
  const [citiesDisabled, setCitiesDisabled] = useState(true);
  const [phoneCode, setPhoneCode] = useState<string>(""); // Variable de estado para el código de país

  const defaultValues = {
    ...initialValues,
  };

  useEffect(() => {
    const countryOptions: Option[] = countriesData.map((country) => ({
      value: country.name,
      label: country.name,
    }));
    setCountries(countryOptions);

    // Establecer el código de país inicial si existe en initialValues
    if (initialValues.country) {
      const initialCountry = countriesData.find(
        (country) => country.name === initialValues.country
      );
      if (initialCountry?.phone_code) {
        const code = `+${initialCountry.phone_code}`;
        setPhoneCode(code);
        form.setFieldsValue({ countryCode: code });
      }
    }
  }, []);

  const handleCountryChange = (countryName: string) => {
    const selectedCountry = countriesData.find(
      (country) => country.name === countryName
    );

    if (selectedCountry?.states?.length) {
      const stateOptions: Option[] = selectedCountry.states.map((state) => ({
        value: state.name,
        label: state.name,
      }));
      setStates(stateOptions);
      setStatesDisabled(false);
    } else {
      setStates([]);
      setStatesDisabled(true);
    }

    setCities([]);
    setCitiesDisabled(true);
    form.setFieldsValue({ state: undefined, city: undefined });

    if (selectedCountry?.phone_code) {
      const code = `+${selectedCountry.phone_code}`;
      setPhoneCode(code);
      form.setFieldsValue({ countryCode: code });
    } else {
      setPhoneCode("");
      form.setFieldsValue({ countryCode: undefined });
    }
  };

  const handleStateChange = (stateName: string) => {
    const countryName = form.getFieldValue("country");
    const selectedCountry = countriesData.find(
      (country) => country.name === countryName
    );
    const selectedState = selectedCountry?.states.find(
      (state) => state.name === stateName
    );

    if (selectedState?.cities?.length) {
      const cityOptions: Option[] = selectedState.cities.map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(cityOptions);
      setCitiesDisabled(false);
    } else {
      setCities([]);
      setCitiesDisabled(true);
    }

    form.setFieldsValue({ city: undefined });
  };

  const handleSubmit = (values: any) => {
    updateFormData(values);
    nextStep();
  };

  const getBusinessOptions = () => {
    switch (businessModel) {
      case "restaurant":
        return [
          { value: "restaurant", label: "Restaurante" },
          { value: "bar", label: "Bar" },
          { value: "nightclub", label: "Discoteca" },
        ];
      case "medical":
        return [
          { value: "medical-office", label: "Consultorio médico" },
          { value: "legal-office", label: "Consultorio jurídico" },
        ];
      case "e-commerce":
        return [{ value: "e-commerce", label: "E-commerce" }];
      default:
        return [];
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={defaultValues}
      style={{ width: "100%" }}
    >
      {/* Información básica */}
      <Form.Item
        label="Nombre del establecimiento"
        name="name_establishment"
        rules={[
          {
            required: true,
            message: "Por favor ingrese el nombre del establecimiento",
          },
        ]}
      >
        <Input
          size="large"
          placeholder="Ingrese el nombre del establecimiento"
        />
      </Form.Item>

      <Form.Item
        label="Tipo de establecimiento"
        name="type"
        rules={[
          {
            required: true,
            message: "Por favor seleccione el tipo de establecimiento",
          },
        ]}
      >
        <Select
          size="large"
          placeholder="Seleccione el tipo"
          options={getBusinessOptions()}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      {/* Ubicación */}
      <Form.Item
        label="País"
        name="country"
        rules={[{ required: true, message: "Por favor seleccione el país" }]}
      >
        <Select
          size="large"
          placeholder="Seleccione el país"
          options={countries}
          onChange={handleCountryChange}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Estado / Departamento"
        name="state"
        rules={[
          {
            required: true,
            message: "Por favor seleccione el estado / departamento",
          },
        ]}
      >
        <Select
          size="large"
          placeholder="Seleccione el estado / departamento"
          options={states}
          disabled={statesDisabled}
          onChange={handleStateChange}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Ciudad"
        name="city"
        rules={[{ required: true, message: "Por favor seleccione la ciudad" }]}
      >
        <Select
          size="large"
          placeholder="Seleccione la ciudad"
          options={cities}
          disabled={citiesDisabled}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Teléfono"
        name="phone"
        rules={[
          { required: true, message: "Por favor ingrese el teléfono" },
          { pattern: /^\d+$/, message: "Por favor ingrese solo números" },
        ]}
      >
        <Input
          size="large"
          addonBefore={
            <Form.Item name="countryCode" noStyle>
              <Select
                style={{ width: 120, borderRadius: "8px" }}
                size="large"
                value={phoneCode}
                disabled
              >
                <Select.Option value={phoneCode}>{phoneCode}</Select.Option>
              </Select>
            </Form.Item>
          }
          style={{ borderRadius: "8px" }}
          placeholder="Ingrese el teléfono (solo dígitos)"
        />
      </Form.Item>

      <Form.Item
        label="Correo electrónico"
        name="email"
        rules={[
          {
            required: true,
            message: "Por favor ingrese el correo electrónico",
          },
          {
            type: "email",
            message: "Por favor ingrese un correo electrónico válido",
          },
        ]}
      >
        <Input size="large" placeholder="Ingrese el correo electrónico" />
      </Form.Item>

      <Form.Item
        label="Dirección"
        name="address"
        rules={[{ required: true, message: "Por favor ingrese la dirección" }]}
      >
        <Input size="large" placeholder="Ingrese la dirección" />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}