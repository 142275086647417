import React, { useState } from "react";
import CustomSidenav from "../../components/sidebar/Sidebar";
import DashboardRoutes from "../../utils/DashboardRoutes";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SettingOutlined, LogoutOutlined, WhatsAppOutlined, UserOutlined } from "@ant-design/icons";

import '../../assets/css/dashboard.css';

const { Header, Content } = Layout;
const { Text } = Typography;

interface DashboardProps {
  signOut: () => void;
  user_data: any;
}

const Dashboard: React.FC<DashboardProps> = ({ signOut, user_data }) => {
  const [ titleSection, setTitleSection ] = useState("");
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
    window.location.reload();
  };

  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    height: 'calc(100vh - 65px)',
    padding: '20px',
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <Link rel="noopener noreferrer" to="/dashboard/whatsapp-integration">
          Integración
        </Link>
      ),
      icon: <WhatsAppOutlined />,
      key: '0',
    },
    {
      label: (
        <Link rel="noopener noreferrer" to="/dashboard/user-profile">
          Perfil
        </Link>
      ),
      icon: <UserOutlined />,
      key: '1',
    },
  ];

  const breadcrumbItems = [
    {
      key: 'dashboard',
      title: <Link to="/dashboard">Dashboard</Link>,
    },
  ];

  if (titleSection !== "Dashboard") {
    breadcrumbItems.push({
        key: titleSection,
        title: <Text>{titleSection}</Text>,
    });
  }

  return (
    <Layout hasSider style={{ minHeight: "100vh" }} className="layout-dashboard">
      <CustomSidenav signOut={signOut} user_data={user_data} />
      <Layout>
        <Header style={{ background: "#FFFFFF" }}>
          <Row gutter={[24, 0]}>
              <Col span={24} md={12}>
                <Breadcrumb items={breadcrumbItems} />
                <div className="ant-page-header-heading">
                  <span
                    className="ant-page-header-heading-title"
                    style={{ textTransform: "capitalize" }}
                  >
                    {titleSection}
                  </span>
                </div>
              </Col>
              <Col span={24} md={12} className="header-control">
                <Flex align="center" justify="end" gap={8}>
                  <Text>{user_data?.family_name}</Text>
                  <Dropdown menu={{ items }}>
                    <Button type="text" icon={<SettingOutlined />} />
                  </Dropdown>
                  <Tooltip title="Cerrar sesión">
                    <Button
                      type="text"
                      icon={<LogoutOutlined />}
                      onClick={handleSignOut}
                    />
                  </Tooltip>
                </Flex>
              </Col>
          </Row>
        </Header>
        <Content style={siderStyle}>
          <div style={{ borderRadius: 10 }}>
            {user_data ? (
              <DashboardRoutes user_data={user_data} setTitleSection={setTitleSection} />
            ) : (
              <Spin size="large" style={{ display: "block", margin: "auto" }} />
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
